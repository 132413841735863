import axiosInstance from "./instances/axiosInstance";
import {apiRoutes} from "../utils/routes";
import store from '../redux/store';
import { login as reduxLogin, logoutUser } from '../redux/slices/authSlice';
import { jwtDecode } from "jwt-decode";

const isTokenExpired = (token) => {
    if (!token) return true;
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
};

export const login = (data) => axiosInstance.post(apiRoutes.login, data).then(response => response.data);
export const register = (data) => axiosInstance.post(apiRoutes.register, data).then(response => response.data);
export const blacklist = () => axiosInstance.post(apiRoutes.blacklist,{"refresh": localStorage.getItem('refreshToken')}).then(response => response.data);
export const refreshAccessToken = async () => {
    console.log("Refreshing access token.")
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken || isTokenExpired(refreshToken)) {
            throw new Error('No refresh token available');
        }
        const response = await axiosInstance.post(apiRoutes.refresh, { refresh: refreshToken });
        // Use the renamed redux login action
        store.dispatch(reduxLogin({ access: response.data.access, refresh: response.data.refresh }));
        return response.data.access;
    } catch (error) {
        console.error('Failed to refresh token', error);
        store.dispatch(logoutUser());
        throw error;
    }
};
export const refreshAccessTokenIfNeeded = async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken || isTokenExpired(accessToken)) {
        try {
            return await refreshAccessToken();
        } catch (error) {
            console.error('Failed to refresh token', error);
            store.dispatch(logoutUser());
            throw error;
        }
    }
    return accessToken; // Return existing token if it's not expired
};

