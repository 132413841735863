import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import AddExercisesModal from "./AddExercisesModal";
import ListWorkoutExercises from "./ListWorkoutExercises";
import { timestampToString } from "../../../utils/helpers";
import { encodeWorkoutStateToURL } from "../../../utils/encoding";
import { clearWorkout } from "../../../redux/slices/workoutSlice";
import * as api from "../../../api/workoutApi";
import QRCode from 'qrcode.react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from 'react-bootstrap/Modal';
import Timer from "./Timer";  // Import the Timer component

const NewWorkout = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [showExerciseModal, setShowExerciseModal] = useState(false);
    const reduxWorkoutName = useSelector((state) => state.workout.name);

    const [workoutName, setWorkoutName] = useState(reduxWorkoutName || '');
    const [isDefaultNameSet, setIsDefaultNameSet] = useState(false);

    const [showSaveModal, setShowSaveModal] = useState(false);
    const exercises = useSelector((state) => state.workout.exercises);
    const workout_id = useSelector((state) => state.workout.id);
    const [qrValue, setQrValue] = useState('');
    const [showQRModal, setShowQRModal] = useState(false);

    const startTimeFromStorage = localStorage.getItem("workoutStartTime");
    const [startTime, setStartTime] = useState(startTimeFromStorage ? parseInt(startTimeFromStorage, 10) : Date.now());

    const createWorkoutMutation = useMutation(api.createWorkout, {
        onSuccess: () => {
            toast.success("Saved a new workout!");
            queryClient.invalidateQueries('workout');
            dispatch(clearWorkout());
            localStorage.removeItem("workoutStartTime");
        },
        onError: (error) => {
            console.error(error);
            toast.error("Could not save a new workout!");
        },
    });

    const updateWorkoutMutation = useMutation(api.putWorkout, {
        onSuccess: () => {
            toast.success("Updated workout template!");
            queryClient.invalidateQueries('workout');
            dispatch(clearWorkout());
            localStorage.removeItem("workoutStartTime");
        },
        onError: (error) => {
            console.error(error);
            toast.error("Could not update workout template!");
        },
    });

    useEffect(() => {
        localStorage.setItem("workoutStartTime", startTime.toString());
    }, [startTime]);

    const handleSaveWorkoutButton = () => {
        setShowSaveModal(true);
    }

    const handleSaveWorkout = () => {
        const payload = {
            status: "Finished",
            name: workoutName,
            workout_exercises: exercises,
            start_time: new Date(startTime).toISOString(),
            end_time: new Date().toISOString()
        };

        createWorkoutMutation.mutate(payload);
        console.log(payload);
        setShowSaveModal(false);
        localStorage.removeItem("workoutStartTime");
    }

    const handleDiscardWorkout = () => {
        dispatch(clearWorkout());
        localStorage.removeItem("workoutStartTime");
    };

    const handleCreateTemplate = () => {
        const template_payload = {
            "status": "Template",
            "name": workoutName,
            "workout_exercises": exercises,
            "start_time": new Date(startTime).toISOString(),
            "end_time": new Date().toISOString()
        };
        createWorkoutMutation.mutate(template_payload);
    }

    const handleUpdateTemplate = () => {
        const template_payload = {
            status: "Template",
            name: workoutName,
            workout_exercises: exercises,
            start_time: new Date(startTime).toISOString(),
            end_time: new Date().toISOString()
        };
        updateWorkoutMutation.mutate({ id: workout_id, data: template_payload });
    };

    const handleSaveAndTemplate = () => {
        handleCreateTemplate();
        handleSaveWorkout();
    }

    useEffect(() => {
        // Set the default workout name only once if it is empty
        if (!reduxWorkoutName & !isDefaultNameSet) {
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const day = days[new Date().getDay()];
            const hour = new Date().getHours();
            const timeOfDay = hour < 12 ? 'Morning' : hour < 18 ? 'Afternoon' : 'Evening';
            setWorkoutName(`${day} ${timeOfDay} Workout`);
        }
    }, [isDefaultNameSet, reduxWorkoutName]);

    const SaveModal = ({ show, onHide, onUpdateTemplate, onCreateTemplate, onSaveAndTemplate, onSaveWorkout }) => {
        return (
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Finish Workout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    What would you like to do with this workout?
                </Modal.Body>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Button variant="outline-primary" onClick={onUpdateTemplate} className="mt-2 w-100">Update Template</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={onCreateTemplate} className="mt-2 w-100">Create Template</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="outline-success" onClick={onSaveAndTemplate} className="mt-2 w-100">Save Workout & Create Template</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="success" onClick={onSaveWorkout} className="mt-2 w-100">Save Workout</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }

    const handleInviteButton = () => {
        const template_payload = {
            "status": "Template",
            "name": workoutName,
            "workout_exercises": exercises,
            "start_time": new Date(startTime).toISOString(),
            "end_time": new Date().toISOString()
        };
        openQRModal(template_payload);
    }

    const openQRModal = async (workout) => {
        const qrLink = await encodeWorkoutStateToURL(workout);
        if (qrLink) {
            setQrValue(qrLink);
            setShowQRModal(true);
        } else {
            toast.error("Error generating QR code.");
        }
    };

    return (
        <div className="mt-2">
            <div className="badge rounded-pill bg-primary mb-2">Current Workout</div>
            <input
                type="text"
                placeholder="Enter workout name"
                value={workoutName}
                onChange={(e) => setWorkoutName(e.target.value)}
                className="form-control mb-3"
                style={{ fontSize: '1.5rem', fontWeight: '500' }}
            />
            <Row className="align-items-center">
                <Col className="text-secondary">
                    <div>
                        <strong>Status:</strong> Started | {timestampToString(new Date())}
                    </div>
                </Col>
            </Row>
            <ListWorkoutExercises />
            <div className="mt-4">
                <Row>
                    <Col xs="auto">
                        <Button variant="primary py-0" onClick={() => setShowExerciseModal(true)}>
                            <i className="fa fa-plus" aria-hidden="true" /> Exercises
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="mt-4">
                <Row className="align-items-center">
                    <Col className="text-center text-body">
                        <Timer startTime={startTime} />
                    </Col>
                </Row>
            </div>
            <div className="mt-4">
                <Row>
                    <Col>
                        <Button variant="outline-danger" className="w-100" onClick={handleDiscardWorkout}>Cancel</Button>
                    </Col>
                    <Col>
                        <Button variant="outline-primary" type="submit" className="w-100" onClick={handleInviteButton}>Invite</Button>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <Button variant="primary" type="submit" className="w-100" onClick={handleSaveWorkoutButton}>Finish Workout</Button>
                    </Col>
                </Row>
            </div>
            <SaveModal
                show={showSaveModal}
                onHide={() => setShowSaveModal(false)}
                onCreateTemplate={handleCreateTemplate}
                onUpdateTemplate={handleUpdateTemplate}
                onSaveAndTemplate={handleSaveAndTemplate}
                onSaveWorkout={handleSaveWorkout}
            />
            <AddExercisesModal
                show={showExerciseModal}
                onHide={() => setShowExerciseModal(false)}
            />
            <Modal show={showQRModal} onHide={() => setShowQRModal(false)} centered>
                <Modal.Header>
                    <Modal.Title>Scan the QR to join this workout</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                    <QRCode value={qrValue} size={360} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default NewWorkout;
