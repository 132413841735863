import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { ListGroup, Row, Col, Dropdown, Button } from "react-bootstrap";
import AddWorkoutExerciseDetails from "./AddWorkoutExerciseDetails";
import { deleteExercise, addSupersetSet, reorderExercises, updateExerciseNote, removeSuperset, replaceExercisesInDetail } from "../../../redux/slices/workoutSlice";
import ExerciseDetailsModal from '../../Exercises/FilterExercises/ExerciseDetailsModal';
import { useConfirmationModalContext } from "../../Common/ConfirmationModalContext";
import { useQuery } from 'react-query';
import Form from "react-bootstrap/Form";
import { getExercise } from '../../../api/exerciseApi';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddExercisesModal from "./AddExercisesModal";

const ListWorkoutExercises = () => {
    const exercises = useSelector((state) => state.workout.exercises);
    const dispatch = useDispatch();
    const { requestConfirm } = useConfirmationModalContext();

    const [selectedExerciseId, setSelectedExerciseId] = useState(null);
    const [exerciseNotes, setExerciseNotes] = useState({}); // State for exercise notes
    const [editNotes, setEditNotes] = useState({}); // State to control notes edit mode
    const [showAddExerciseModal, setShowAddExerciseModal] = useState(false);
    const [replaceIndex, setReplaceIndex] = useState(null);
    const [replaceSuperset, setReplaceSuperset] = useState(null);
    const [draggedSuperset, setDraggedSuperset] = useState(null);

    // Initialize exercise notes and edit notes state when exercises change
    useEffect(() => {
        const initialNotes = exercises.reduce((acc, exercise, index) => {
            if (exercise.notes) {
                acc[index] = exercise.notes;
            }
            return acc;
        }, {});

        const initialEditNotes = exercises.reduce((acc, exercise, index) => {
            acc[index] = false;
            return acc;
        }, {});

        setExerciseNotes(initialNotes);
        setEditNotes(initialEditNotes);
    }, [exercises]);

    // Fetch exercise details when an exercise is selected
    const { data: fetchedExercise, isSuccess } = useQuery(['exercise', selectedExerciseId], () => getExercise(selectedExerciseId), {
        enabled: !!selectedExerciseId, // This will only run the query if `selectedExerciseId` is not null
    });

    const onExerciseInfoClick = (exercise) => {
        if (exercise.exercise) {
            setSelectedExerciseId(exercise.exercise);
        } else {
            setSelectedExerciseId(exercise.id);
        }
    };

    const handleCloseExerciseModal = () => {
        setSelectedExerciseId(null);
    };

    const handleDelete = (index) => {
        requestConfirm({
            message: "Are you sure you want to delete this exercise from the workout?",
            onConfirm: () => dispatch(deleteExercise(index))
        });
    };

    const handleAddSupersetSet = (supersetNumber) => {
        dispatch(addSupersetSet({ supersetNumber }));
    };

    const handleRemoveSuperset = (supersetNumber) => {
        requestConfirm({
            message: `Are you sure you want to remove Superset ${supersetNumber}?`,
            onConfirm: () => dispatch(removeSuperset({ supersetNumber }))
        });
    };

    const handleReplaceExercise = (index, supersetNumber) => {
        setReplaceIndex(index);
        setReplaceSuperset(supersetNumber);
        setShowAddExerciseModal(true);
    };

    const isStartOfSuperset = (index) => {
        if (index === 0) return true; // The first item in the list
        const currentSupersetNumber = getSupersetNumber(exercises[index].workout_exercise_details);
        const previousSupersetNumber = getSupersetNumber(exercises[index - 1].workout_exercise_details);
        return currentSupersetNumber !== previousSupersetNumber;
    };

    const isEndOfSuperset = (index) => {
        if (index === exercises.length - 1) return true; // The last item in the list
        const currentSupersetNumber = getSupersetNumber(exercises[index].workout_exercise_details);
        const nextSupersetNumber = getSupersetNumber(exercises[index + 1].workout_exercise_details);
        return currentSupersetNumber !== nextSupersetNumber;
    };

    const getSupersetNumber = (exerciseDetails) => {
        const detailWithSuperset = exerciseDetails.find(detail => detail.superset_number != null);
        return detailWithSuperset ? detailWithSuperset.superset_number : null;
    };

    const getSupersetClass = (exerciseDetails) => {
        const superset_number = getSupersetNumber(exerciseDetails);
        if (superset_number == null) return "bg-regular"; // Correctly handle null case
        return superset_number % 2 === 0 ? "bg-superset-even" : "bg-superset-odd";
    };

    const onDragStart = (start) => {
        const draggedItem = exercises[start.source.index];
        const supersetNumber = getSupersetNumber(draggedItem.workout_exercise_details);
        if (isStartOfSuperset(start.source.index)) {
            setDraggedSuperset(supersetNumber);
        }
    };

    const onDragEnd = (result) => {
        setDraggedSuperset(null);
        if (!result.destination) return;
        dispatch(reorderExercises({
            startIndex: result.source.index,
            endIndex: result.destination.index
        }));
    };

    const toggleEditNotes = (index) => {
        setEditNotes({
            ...editNotes,
            [index]: !editNotes[index]
        });
    };

    const handleNoteChange = (index, note) => {
        setExerciseNotes({
            ...exerciseNotes,
            [index]: note
        });
    };

    const handleNoteBlur = (index) => {
        dispatch(updateExerciseNote({
            exerciseIndex: index,
            newNote: exerciseNotes[index]
        }));
        setEditNotes({
            ...editNotes,
            [index]: false
        });
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <Droppable droppableId="exercises">
                    {(provided) => (
                        <Row className="align-items-center">
                            <ListGroup variant="flush" className="scroll pe-0" {...provided.droppableProps} ref={provided.innerRef}>
                                {exercises.map((exercise, index) => {
                                    const isSuperset = getSupersetNumber(exercise.workout_exercise_details) != null;
                                    const supersetNumber = getSupersetNumber(exercise.workout_exercise_details);

                                    if (draggedSuperset !== null && draggedSuperset === supersetNumber) {
                                        if (isStartOfSuperset(index)) {
                                            return (
                                                <Draggable key={exercise.id} draggableId={exercise.id.toString()} index={index}>
                                                    {(provided) => (
                                                        <ListGroup.Item
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`${getSupersetClass(exercise.workout_exercise_details)}`}
                                                        >
                                                            <Row className="align-items-center mb-2">
                                                                <Col>
                                                                    <h6 className="mb-0">Superset {supersetNumber}</h6>
                                                                </Col>
                                                            </Row>
                                                        </ListGroup.Item>
                                                    )}
                                                </Draggable>
                                            );
                                        } else {
                                            return null;
                                        }
                                    }

                                    return (
                                        <Draggable key={exercise.id} draggableId={exercise.id.toString()} index={index}>
                                            {(provided) => (
                                                <ListGroup.Item
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`${getSupersetClass(exercise.workout_exercise_details)}`}
                                                >
                                                    {isSuperset && isStartOfSuperset(index) && (
                                                        <Row className="align-items-center mb-2">
                                                            <Col>
                                                                <h6 className="mb-0">Superset {supersetNumber}</h6>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Dropdown align="end">
                                                                    <Button variant="secondary-outline" className="px-2 py-0" id="dropdown-custom-components">
                                                                        <Dropdown.Toggle as="span" bsPrefix="custom-toggle">
                                                                            <i className="fas fa-ellipsis"></i> {/* Font Awesome three dots icon */}
                                                                        </Dropdown.Toggle>
                                                                    </Button>
                                                                    <Dropdown.Menu className='bg-body'>
                                                                        <Dropdown.Item onClick={() => handleRemoveSuperset(supersetNumber)}>
                                                                            Remove Superset
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <section>
                                                        <Row>
                                                            <Col>
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                                    <h6 className="text-primary mb-0" style={{ cursor: "pointer" }} onClick={() => onExerciseInfoClick(exercise)}>
                                                                        {exercise.name} ({exercise.type}) <i className="fa fa-circle-info" aria-hidden="true" />
                                                                    </h6>
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Dropdown align="end">
                                                                    <Button variant="secondary-outline" className="px-2 py-0" id="dropdown-custom-components">
                                                                        <Dropdown.Toggle as="span" bsPrefix="custom-toggle">
                                                                            <i className="fas fa-ellipsis"></i> {/* Font Awesome three dots icon */}
                                                                        </Dropdown.Toggle>
                                                                    </Button>
                                                                    <Dropdown.Menu className='bg-body'>
                                                                        <Dropdown.Item onClick={() => toggleEditNotes(index)}>
                                                                            {exerciseNotes[index] ? "Edit Note" : "Add Note"}
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => handleReplaceExercise(index, supersetNumber)}>
                                                                            Replace Exercise
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => handleDelete(index)}>
                                                                            Delete
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </Col>
                                                        </Row>
                                                        {(exerciseNotes[index] || editNotes[index]) && (
                                                            <Form.Group className="mb-2">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={2}
                                                                    value={exerciseNotes[index] || ""}
                                                                    onChange={(e) => handleNoteChange(index, e.target.value)}
                                                                    onBlur={() => handleNoteBlur(index)}
                                                                    disabled={!editNotes[index]}
                                                                />
                                                            </Form.Group>
                                                        )}
                                                        <AddWorkoutExerciseDetails exercise={exercise} exerciseIndex={index} isSuperset={isSuperset} />
                                                        {isSuperset && isEndOfSuperset(index) && (
                                                            <Button variant="secondary" size="sm" onClick={() => handleAddSupersetSet(supersetNumber)} className="mt-2 py-0">+ Add Superset</Button>
                                                        )}
                                                    </section>
                                                </ListGroup.Item>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </ListGroup>
                            {isSuccess && (
                                <ExerciseDetailsModal
                                    exercise={fetchedExercise}
                                    show={!!fetchedExercise}
                                    onHide={handleCloseExerciseModal}
                                />
                            )}
                        </Row>
                    )}
                </Droppable>
            </DragDropContext>

            <AddExercisesModal
                show={showAddExerciseModal}
                onHide={() => setShowAddExerciseModal(false)}
                is_replace={true}
                replace_index={replaceIndex}
                replace_superset={replaceSuperset}
            />
        </>
    );
};

export default ListWorkoutExercises;
