import React from 'react';
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";

const WorkoutExerciseDetails = ({ exerciseDetails }) => {
    const weightUnit = useSelector(state => state.auth.weightUnit);

    // Check for the presence of each optional column in the dataset
    const hasWeight = exerciseDetails.some(detail => detail.weight);
    const hasTempo = exerciseDetails.some(detail => detail.tempo);
    const hasDuration = exerciseDetails.some(detail => detail.duration);
    const hasDistance = exerciseDetails.some(detail => detail.distance);
    const hasBarSpeed = exerciseDetails.some(detail => detail.avg_bar_speed);
    const hasRpe = exerciseDetails.some(detail => detail.rpe);
    const hasRir = exerciseDetails.some(detail => detail.rir);
    const hasRest = exerciseDetails.some(detail => detail.rest);

    return (
            <Table striped hover size="sm" style={{ fontSize: "0.75rem" }} >
                <thead>
                    <tr>
                        <th style={{ width: '4.375rem' }}>Reps</th>
                        {hasWeight && <th style={{ width: '4.375rem' }}>Weight</th>}
                        {hasTempo && <th style={{ width: '4.375rem' }}>Tempo</th>}
                        {hasDuration && <th style={{ width: '4.375rem' }}>Duration</th>}
                        {hasDistance && <th style={{ width: '4.375rem' }}>Distance</th>}
                        {hasBarSpeed && <th style={{ width: '4.375rem' }}>B-Speed</th>}
                        {hasRpe && <th style={{ width: '4.375rem' }}>RPE</th>}
                        {hasRir && <th style={{ width: '4.375rem' }}>RIR</th>}
                        {hasRest && <th style={{ width: '4.375rem' }}>Rest</th>}
                        <th style={{ border: 'none', backgroundColor: 'transparent', boxShadow: 'none' }} className="text-end"></th>
                    </tr>
                </thead>
                <tbody>
                    {exerciseDetails.map(detail => (
                        <tr key={detail.id}>
                            <td>{detail.reps}</td>
                            {hasWeight && <td>{detail.weight || '-'} {weightUnit}</td>}
                            {hasTempo && <td>{detail.tempo || '-'}</td>}
                            {hasDuration && <td>{detail.duration || '-'}</td>}
                            {hasDistance && <td>{detail.distance || '-'} m</td>}
                            {hasBarSpeed && <td>{detail.avg_bar_speed || '-'} m/s</td>}
                            {hasRpe && <td>{detail.rpe || '-'}</td>}
                            {hasRir && <td>{detail.rir || '-'}</td>}
                            {hasRest && <td>{detail.rest || '-'}</td>}
                            <td className="text-end" style={{ border: 'none', backgroundColor: 'transparent', boxShadow: 'none' }}>
                                {detail.completed ? <i className="fa fa-check text-success" aria-hidden="true"/> : ""}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
    );
};

export default WorkoutExerciseDetails;
