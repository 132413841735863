import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import React, { useEffect, useState } from 'react';
import { Button, ListGroup, Modal, Row, Col, Stack, Spinner, DropdownButton, Dropdown } from 'react-bootstrap';
import { initializeWorkout } from "../../redux/slices/workoutSlice"; 
import { startWorkout } from "../../redux/slices/workoutSlice";
import QRCode from 'qrcode.react';
import { toast } from "react-toastify";

import NewWorkout from "../Workout/NewWorkout";
import * as api from "../../api/workoutApi";
import * as userWorkoutHistoryApi from "../../api/userworkouthistoryApi";

import { useConfirmationModalContext } from "../Common/ConfirmationModalContext";
import FolderList from "../Workout/NewFolder/ListFolders";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import WorkoutHistoryItem from "./WorkoutHistoryItem";

import { useAddWorkoutToFolderMutation, useRemoveWorkoutFromFolderMutation } from "../../hooks/folderHooks";

import { encodeWorkoutUUIDToURL, decodeWorkoutStateFromURL, encodeUserWorkoutHistoryShareTokenToURL } from "../../utils/encoding";

import { 
    Chart as ChartJS, 
    CategoryScale, 
    LinearScale, 
    BarElement, 
    Title, 
    Tooltip, 
    Legend 
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale, 
    LinearScale, 
    BarElement, 
    Title, 
    Tooltip, 
    Legend
);

const prepareChartData = (workouts) => {
    const endDate = new Date();
    const startDate = new Date(new Date().setDate(endDate.getDate() - 30));

    let labels = [];
    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
        labels.push(`${date.getDate()}-${date.getMonth() + 1}`);
    }
    labels.reverse();

    const countPerDay = workouts.reduce((acc, workout) => {
        const date = `${new Date(workout.start_time).getDate()}-${new Date(workout.start_time).getMonth() + 1}`;
        acc[date] = (acc[date] || 0) + 1;
        return acc;
    }, {});

    const data = labels.map(label => countPerDay[label] || 0);

    return {
        labels,
        datasets: [{
            label: 'Number of Workouts',
            data,
            backgroundColor: 'rgb(0, 123, 255)', // Bootstrap primary color (Blue)
            borderRadius: 5, // Adjust the radius value to your preference
        }]
    };
};

const WorkoutHistory = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [showQRModal, setShowQRModal] = useState(false);
    const [qrValue, setQrValue] = useState('');
    const workoutStarted = useSelector((state) => state.workout.workoutStarted);
    const [workoutView, setWorkoutView] = useState('Finished'); // 'Template' or 'Finished'
    const [selectedUser, setSelectedUser] = useState(null);
    const { data: workouts, isLoading: workoutsLoading } = useQuery(['workout', selectedUser, workoutView], () => api.getWorkoutHistory(workoutView, selectedUser));
    const { data: accessibleUsers, isLoading: usersLoading } = useQuery('accessibleUsers', userWorkoutHistoryApi.getUsersWhosWorkoutHistoryCanBeViewed);
    const addWorkoutToFolderMutation = useAddWorkoutToFolderMutation(); 
    const removeWorkoutFromFolderMutation = useRemoveWorkoutFromFolderMutation();
    const chartData = prepareChartData(workouts || []);

    useEffect(() => {
        let isMounted = true;
    
        const fetchData = async () => {
            try {
                const workoutStateFromURL = await decodeWorkoutStateFromURL();
                if (workoutStateFromURL && isMounted) {
                    dispatch(initializeWorkout(workoutStateFromURL));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    
        return () => {
            isMounted = false;
        };
    }, [dispatch]);

    useEffect(() => {
        if (accessibleUsers && accessibleUsers.length > 0) {
            setSelectedUser(accessibleUsers[0].username); // Set default user to the first one in the list
        }
    }, [accessibleUsers]);

    const onDragEnd = (result) => {
        const { source, destination, draggableId } = result;
    
        if (!destination || destination.droppableId === source.droppableId) {
            return; // Do nothing if dropped outside or within the same folder
        }
    
        const workoutId = draggableId.replace('workout-', '');
    
        if (destination.droppableId === 'no-folder') {
            removeWorkoutFromFolderMutation.mutate({
                folderId: source.droppableId,
                workoutId: workoutId
            });
        } else {
            addWorkoutToFolderMutation.mutate({
                folderId: destination.droppableId,
                workoutIds: [workoutId]
            });
        }
    };
    
    const deleteWorkoutMutation = useMutation(api.deleteWorkout, {
        onError: (error) => {
            console.error(error);
            toast.error("Could not delete workout!");
        },
        onSuccess: () => {
            toast.info("Workout has been deleted!");
            queryClient.invalidateQueries("workout");
        },
    });

    const { requestConfirm } = useConfirmationModalContext();
    const handleDeleteWorkout = (workout_id) => {
        requestConfirm({
            message: "Are you sure you want to delete this workout?",
            onConfirm: () => deleteWorkoutMutation.mutate(workout_id),
        });
    };

    const handleGenerateAndCopyLink = async (workout) => {
        const shareLink = await encodeWorkoutUUIDToURL(workout);
        if (shareLink) {
            navigator.clipboard.writeText(shareLink).then(() => {
                toast.info("Share link copied to clipboard!");
            }, (error) => {
                console.error('Clipboard copy failed:', error);
                toast.error("Error copying share link.");
            });
        } else {
            toast.error("Error generating share link.");
        }
    };

    const openQRModal = async (workout) => {
        const qrLink = await encodeWorkoutUUIDToURL(workout);
        if (qrLink) {
            setQrValue(qrLink);
            setShowQRModal(true);
        } else {
            toast.error("Error generating QR code.");
        }
    };

    if (workoutStarted) {
        return <NewWorkout />;
    } else {
        return (
            <div>
                <div className="sticky-top p-2">
                    <Stack direction="horizontal" gap={3} className="mb-2">
                        <div className="me-auto">
                            {selectedUser ? (
                                <span>You are viewing workout history from: </span>
                            ) : (
                                <span>Select a user to view their workout history: </span>
                            )}
                        </div>
                        <DropdownButton id="dropdown-basic-button"  className="px-0" title={selectedUser || "Select User"}>
                            {accessibleUsers && accessibleUsers.map((user) => (
                                <Dropdown.Item key={user.username} onClick={() => setSelectedUser(user.username)}>
                                    {user.username}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Stack>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="chart-container" style={{ width: '100%' }}>
                        {!workoutsLoading && workouts && workouts.length > 0 && (
                            <Bar data={chartData} options={{
                                plugins: {
                                    legend: {
                                        display: false, // This will hide the legend
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        reverse: true,
                                    },
                                    y: {
                                        title: {
                                            display: true,
                                            text: 'Number of Workouts',
                                        },
                                    },
                                },
                            }} />
                        )}
                    </div>

                    <div className="">
                        <Stack gap={2} className="mb-3">
                            <Row>
                                <Col>
                                    <Button
                                        className="w-100 float-end mx-0 mt-2"
                                        variant="secondary"
                                        onClick={() => dispatch(startWorkout({}))} // Start a new workout without old data
                                    >
                                        <i className="fa fa-plus" aria-hidden="true" />
                                        &nbsp;
                                        New Workout
                                    </Button>
                                </Col>
                            </Row>
                        </Stack>

                        { workoutView === 'Template' && (<FolderList handleDeleteWorkout={handleDeleteWorkout} 
                                    openQRModal={openQRModal} 
                                    handleGenerateAndCopyLink={handleGenerateAndCopyLink}/>)}
                        {workoutsLoading && (
                            <div className="text-center">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        )}

                        {!workoutsLoading && workouts?.length === 0 && (
                            <div className="text-center mt-5">
                                <h5>Nothing to see here... yet.</h5>
                            </div>
                        )}

                        <Droppable key={"no-folder"} droppableId="no-folder">
                            {(provided) => (
                                <ListGroup ref={provided.innerRef} {...provided.droppableProps} variant="flush">
                                    {workouts?.map((workout, index) => (
                                        <Draggable key={workout.id} draggableId={`workout-${workout.id}`} index={index}>
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <WorkoutHistoryItem 
                                                        workout={workout} 
                                                        handleDeleteWorkout={handleDeleteWorkout} 
                                                        openQRModal={openQRModal} 
                                                        handleGenerateAndCopyLink={handleGenerateAndCopyLink}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </ListGroup>
                            )}
                        </Droppable>

                        <Modal show={showQRModal} onHide={() => setShowQRModal(false)} centered>
                            <Modal.Body className="d-flex justify-content-center">
                                <QRCode value={qrValue} size={360} />
                            </Modal.Body>
                        </Modal>
                    </div>
                </DragDropContext>
            </div>
        );
    }
};

export default WorkoutHistory;
