import axiosInstance from "./instances/axiosInstance";
import {apiRoutes} from "../utils/routes";

// Assuming axiosInstance is already configured with baseURL and other configurations

export const getFolders = () => {
    const url = apiRoutes.folders; // Make sure this route is defined in your apiRoutes
    return axiosInstance.get(url)
        .then(response => {
            // Optionally, log the data for debugging
            // console.log('Folder data received:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error fetching folders:', error);
            throw error; // Re-throw the error to handle it in the component or further up the chain
        });
};

// Function to create a new folder
export const createFolder = (folderData) => {
    const url = apiRoutes.folders;
    return axiosInstance.post(url, folderData)
        .then(response => {
            console.log('Folder created:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error creating folder:', error);
            throw error;
        });
};

// Function to add workouts to an existing folder
export const addWorkoutToFolder = (folderId, workoutIds) => {
    const url = `${apiRoutes.folders}${folderId}/`;  // Make sure to append a slash if your backend framework requires it (like Django)
    return axiosInstance.patch(url, { workouts: workoutIds })
        .then(response => {
            console.log('Workouts added to folder:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error adding workouts to folder:', error);
            throw error;
        });
};

export const removeWorkoutFromFolder = (folderId, workoutId) => {
    const url = `${apiRoutes.folders}${folderId}/remove-workout/`;
    return axiosInstance.post(url, { workout_id: workoutId })
        .then(response => {
            console.log('Workout removed from folder:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error removing workout from folder:', error);
            throw error;
        });
};

export const deleteFolder = (folderId) => {
    const url = `${apiRoutes.folders}${folderId}/`;
    return axiosInstance.delete(url)
        .then(response => {
            console.log('Folder deleted:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error deleting folder:', error);
            throw error;
        });
};

export const renameFolder = (folderId, newName) => {
    const url = `${apiRoutes.folders}${folderId}/rename-folder/`;
    return axiosInstance.patch(url, { name: newName })
        .then(response => {
            console.log('Folder renamed:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error renaming folder:', error);
            throw error;
        });
};


// Function to generate a token for folder access
export const generateTokenForFolder = (folderId, validityDurationMinutes = 60) => {
    const url = `${apiRoutes.folders}${folderId}/generate-token/`;
    return axiosInstance.post(url, { validity_duration_minutes: validityDurationMinutes })
        .then(response => {
            console.log('Token generated for folder:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error generating token for folder:', error);
            throw error;
        });
};

// Function to access a folder using a token
export const accessFolderWithToken = (token) => {
    const url = `${apiRoutes.folders}access-folder/${token}/`;
    return axiosInstance.get(url)
        .then(response => {
            console.log('Accessed folder with token:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error accessing folder with token:', error);
            throw error;
        });
};

