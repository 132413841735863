export const apiRoutes = {
    login: '/auth/login/',
    register: '/auth/register/',
    refresh: '/auth/refresh/',
    blacklist: '/auth/blacklist/',
    profile: '/profile/',
    workout: '/workout/',
    exercise: '/exercise/',
    bodyParts: '/exercise/body_parts/',
    type: '/exercise/type/',
    folders: '/workoutfolders/', 
    userworkouthistory: '/userworkouthistory/',
    integrations: '/integrations/',
};

export const pageRoutes = {
    login: '/login',
    register: '/register',
    profile: '/profile',
    workout: '/workout',
    history: '/history',
    exercises: '/exercises',
};