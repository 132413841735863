import React, { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import ExerciseDetailsModal from './ExerciseDetailsModal';

const ViewExercises = ({exercises}) => {
    const [selectedExercise, setSelectedExercise] = useState(null);
    
    return (
        <>
            <ListGroup variant="flush" className="view-scroll-container">
                {exercises?.map(exercise =>
                    <ListGroup.Item key={exercise.id} action onClick={() => setSelectedExercise(exercise)}>
                        <h6>{exercise.name} ({exercise.type})</h6>
                        {/* <span>{exercise.body_parts}</span> */}
                        <div className="text-secondary">{exercise.description}</div>
                    </ListGroup.Item>
                )}
            </ListGroup>
            {selectedExercise && (
                <ExerciseDetailsModal
                    exercise={selectedExercise}
                    show={!!selectedExercise}
                    onHide={() => setSelectedExercise(null)}
                />
            )}
        </>
    );
};

export default ViewExercises;