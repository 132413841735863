import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import React, { useEffect, useState } from 'react';
import { Button, ListGroup, Modal, Row, Col, Stack, Spinner } from 'react-bootstrap';
// import { Button, ListGroup, Modal, Row, Col, Stack, Spinner, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import { initializeWorkout } from "../../redux/slices/workoutSlice"; // Assuming you have an initializeWorkout action
import { startWorkout,clearWorkout } from "../../redux/slices/workoutSlice";

import QRCode from 'qrcode.react';
import { toast } from "react-toastify";

import NewWorkout from "./NewWorkout";
import * as api from "../../api/workoutApi";
import * as apiFolder from "../../api/folderApi"

import { encodeWorkoutUUIDToURL, decodeWorkoutStateFromURL, checkAccessFolderFromURL, checkAccessUserWorkoutHistoryFromURL } from "../../utils/encoding";
import { useConfirmationModalContext } from "../Common/ConfirmationModalContext";
// Folder related
import FolderList from "./NewFolder/ListFolders";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import WorkoutItem from "./NewWorkout/WorkoutItem";
import FolderNameModal from './NewFolder/FolderNameModal'; // Adjust the import path as needed

import { useAddWorkoutToFolderMutation, useRemoveWorkoutFromFolderMutation} from "../../hooks/folderHooks";

const Workout = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [showQRModal, setShowQRModal] = useState(false);
    const [qrValue, setQrValue] = useState('');
    const workoutStarted = useSelector((state) => state.workout.workoutStarted);
    const { data, isLoading } = useQuery(['workout'], () => api.getWorkoutTemplates());
    const [showFolderModal, setShowFolderModal] = useState(false);
    const addWorkoutToFolderMutation = useAddWorkoutToFolderMutation(); 
    const removeWorkoutFromFolderMutation = useRemoveWorkoutFromFolderMutation()

    useEffect(() => {
        let isMounted = true;
    
        const fetchData = async () => {
            try {
                const workoutStateFromURL = await decodeWorkoutStateFromURL();
                    if (workoutStateFromURL && isMounted) {
                        dispatch(clearWorkout());
                        dispatch(initializeWorkout(workoutStateFromURL));
                    }
                    else {

                        const refresh_folder_message = await checkAccessFolderFromURL();
                        if (refresh_folder_message && isMounted) {
                            dispatch(clearWorkout());
                            queryClient.invalidateQueries('folders');
                            if (refresh_folder_message.startsWith('Error')) {
                                toast.error(refresh_folder_message);
                            } else {
                                toast.success(refresh_folder_message);
                            }
                        } else {

                            const refresh_viewable_users_message = await checkAccessUserWorkoutHistoryFromURL();
                            if (refresh_viewable_users_message && isMounted) {
                                dispatch(clearWorkout());
                                queryClient.invalidateQueries('workouts');
                                if (refresh_viewable_users_message.startsWith('Error')) {
                                    toast.error(refresh_viewable_users_message);
                                } else {
                                    toast.success(refresh_viewable_users_message);
                                }
                            } 
                        }

                    }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    
        return () => {
            isMounted = false;
        };
    }, [dispatch, queryClient]);
    

    // Function to handle creating a new folder
    const createFolderMutation = useMutation(apiFolder.createFolder, {
        onSuccess: () => {
            queryClient.invalidateQueries('folders'); // Invalidate folders query to refetch data
            toast.info('Folder created successfully!');
        },
        onError: (error) => {
            toast.error(`Error creating folder: ${error.message}`);
        }
    });
    
    const handleCreateFolder = (folderName) => {
        createFolderMutation.mutate({ name: folderName });
    };
    // folder related
    const onDragEnd = (result) => {
        const { source, destination, draggableId } = result;
    
        if (!destination || destination.droppableId === source.droppableId) {
            return; // Do nothing if dropped outside or within the same folder
        }
    
        const workoutId = draggableId.replace('workout-', '');
    
        if (destination.droppableId === 'no-folder') {
            removeWorkoutFromFolderMutation.mutate({
                folderId: source.droppableId,
                workoutId: workoutId
            });
        } else {
            addWorkoutToFolderMutation.mutate({
                folderId: destination.droppableId,
                workoutIds: [workoutId]
            });
        }
    };
    
    
    const deleteWorkoutMutation = useMutation(api.deleteWorkout, {
        onError: (error) => {
            console.error(error);
            toast.error("Could not delete workout!");
        },
        onSuccess: () => {
            toast.info("Workout has been deleted!");
            queryClient.invalidateQueries("workout");
        },
    })

    const { requestConfirm } = useConfirmationModalContext();
    const handleDeleteWorkout = (workout_id) => {
        requestConfirm({
            message: "Are you sure you want to delete this workout?",
            onConfirm: () => deleteWorkoutMutation.mutate(workout_id),
        });
    };
    

    const handleGenerateAndCopyLink = async (workout) => {
        const shareLink = await encodeWorkoutUUIDToURL(workout);
        if (shareLink) {
            navigator.clipboard.writeText(shareLink).then(() => {
                toast.info("Share link copied to clipboard!");
            }, (error) => {
                console.error('Clipboard copy failed:', error);
                toast.error("Error copying share link.");
            });
        } else {
            toast.error("Error generating share link.");
        }
    };

    const openQRModal = async (workout) => {
        const qrLink = await encodeWorkoutUUIDToURL(workout);
        if (qrLink) {
            setQrValue(qrLink);
            setShowQRModal(true);
        } else {
            toast.error("Error generating QR code.");
        }
    };
    

    if (workoutStarted) {
        return <NewWorkout />;
    } else {
        return (

            <DragDropContext onDragEnd={onDragEnd}>
                <div className="mt-2">

                    {/* <Stack gap={2} className="mb-1">
                        <Row>
                            <Col>
                                <ToggleButtonGroup type="radio" name="workoutViews" className="w-100" value={workoutView} onChange={setWorkoutView}>
                                    <ToggleButton id="template-workout" className="w-50" value="Template" variant="outline-primary">
                                        Templates
                                    </ToggleButton>
                                    <ToggleButton id="finished-workout" className="w-50" value="Finished" variant="outline-primary">
                                        Completed
                                    </ToggleButton>
                                
                                </ToggleButtonGroup>
                            </Col>
                        </Row>
                    </Stack> */}

                    <Stack gap={2} className="mb-3">
                        <Row>
                        
                            <Col>
                                <Button
                                    className="w-100 mx-0 mt-2"
                                    variant="primary"
                                    onClick={() => setShowFolderModal(true)}
                                >
                                    <i className="fas fa-folder" aria-hidden="true" />
                                    &nbsp;
                                    New Folder
                                </Button>

                                <FolderNameModal
                                    show={showFolderModal}
                                    onHide={() => setShowFolderModal(false)}
                                    onSubmit={handleCreateFolder}
                                />

             
                            </Col>

                            <Col>
                                <Button
                                    className="w-100 float-end mx-0 mt-2"
                                    variant="secondary"
                                    onClick={() => dispatch(startWorkout({}))} // Start a new workout without old data
                                >
                                    <i className="fa fa-plus" aria-hidden="true" />
                                    &nbsp;
                                    New Workout
                                </Button>
                            </Col>
                        </Row>
                    </Stack>

                    { (<FolderList handleDeleteWorkout={handleDeleteWorkout} 
                                openQRModal={openQRModal} 
                                handleGenerateAndCopyLink={handleGenerateAndCopyLink}/>)}
                    {isLoading && (
                        <div className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    )}

                    {!isLoading && data?.length === 0 && (
                        <div className="text-center mt-5">
                            <h5>Nothing to see here... yet.</h5>
                        </div>
                    )}

                    <Droppable key={"no-folder"} droppableId="no-folder">
                        {(provided) => (
                            <ListGroup ref={provided.innerRef} {...provided.droppableProps}  variant="flush">
                                {data?.map((workout, index) => (
                                    <Draggable key={workout.id} draggableId={`workout-${workout.id}`} index={index}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <WorkoutItem 
                                                    workout={workout} 
                                                    handleDeleteWorkout={handleDeleteWorkout} 
                                                    openQRModal={openQRModal} 
                                                    handleGenerateAndCopyLink={handleGenerateAndCopyLink}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ListGroup>
                        )}
                    </Droppable>

                    <Modal show={showQRModal} onHide={() => setShowQRModal(false)} centered>
                        <Modal.Body className="d-flex justify-content-center">
                            <QRCode value={qrValue} size={360} />
                        </Modal.Body>
                    </Modal>

                </div>
            </DragDropContext>
        );
    }


};

export default Workout;
