import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import NewExerciseModal from "../../Exercises/NewExerciseModal";
import FilterExercises from "../../Exercises/FilterExercises";
import SelectExercises from "../../Exercises/FilterExercises/SelectExercises";
import { addExercises, replaceExercisesInDetail } from "../../../redux/slices/workoutSlice";

const AddExercisesModal = (props) => {
    const [showNewExerciseModal, setShowNewExerciseModal] = useState(false);
    const [selectedExercises, setSelectedExercises] = useState([]);
    const dispatch = useDispatch();
    // Assuming your state now keeps track of the current maximum superset number
    const maxSupersetNumber = useSelector((state) => state.workout.maxSupersetNumber);

    const close = () => {
        setSelectedExercises([]);
        props.onHide();
    };

    const handleSelect = (e) => {
        const { id, checked } = e.target;
        if (checked) {
            setSelectedExercises(prevState => [...prevState, parseInt(id)]);
        } else {
            setSelectedExercises(prevState => prevState.filter(i => i !== parseInt(id)));
        }
    };

    const handleAddExercises = (asSuperset = false) => {
        if (selectedExercises.length < 1) {
            toast.error("You did not select any exercises!");
            return;
        }
        if (asSuperset && selectedExercises.length < 2) {
            toast.error("Select at least two exercises to form a superset!");
            return;
        } 

        if (asSuperset && selectedExercises.length > 1) {
            toast.success("You added a superset!");
        } 

        const payload = {
            exercises: selectedExercises,
            // Only assign a new superset number if adding as a superset
            superset_number: asSuperset ? maxSupersetNumber + 1 : null
        };

        dispatch(addExercises(payload));
        close();
    };

    const handleReplaceExercise = () => {
        // enforcing one exercise for now
        if (selectedExercises.length !== 1) {
            toast.error("Please select exactly one exercise to replace.");
            return;
        }
        dispatch(replaceExercisesInDetail({
            supersetNumber: props.replace_superset,
            exerciseIndex: props.replace_index,
            newExercises: selectedExercises
        }));
        close();
    };

    return (
        
        <Modal {...props} size="lg" aria-labelledby="selectExerciseModal" onHide={close}>
            <NewExerciseModal
                show={showNewExerciseModal}
                onHide={() => setShowNewExerciseModal(false)}
            />
            <Modal.Header closeButton>
                <Modal.Title id="selectExerciseModal">Select Exercises or </Modal.Title>
                <Modal.Title id="selectExerciseModal" className="text-primary" onClick={() => setShowNewExerciseModal(true)}>&nbsp;Add New</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FilterExercises RenderExercises={SelectExercises} handleSelect={handleSelect} />
            </Modal.Body>
            <Modal.Footer>

                <Button variant="secondary" onClick={close}>Cancel</Button>
                {props.is_replace ? (
                    <Button variant="primary" onClick={handleReplaceExercise}>Replace Exercise</Button>
                ) : (
                    <>
                        <Button variant="primary" onClick={() => handleAddExercises(false)}>Add Exercises</Button>
                        <Button variant="info" onClick={() => handleAddExercises(true)}>Add as Superset</Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default AddExercisesModal;
