import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

const SelectBodyPart = ({ label, options, selectValue, onSelect }) => (
    <Form.Group className="mb-3">
        <Form.Label>{label}</Form.Label>
        <Form.Select value={selectValue.id} onChange={onSelect}>
            {options.map((option) => (
                <option key={option.id} value={option.id}>
                    {option.name}
                </option>
            ))}
        </Form.Select>
    </Form.Group>
);

SelectBodyPart.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.string,
        })
    ).isRequired,
    selectValue: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string
    }),
    onSelect: PropTypes.func.isRequired
};

export default SelectBodyPart;
