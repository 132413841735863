import {useEffect, useState} from "react";
import {useQuery, useQueryClient} from "react-query";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ExerciseDropdown from "./ExerciseDropdown";
import BodypartDropdown from "./BodypartDropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import * as exerciseApi from "../../../api/exerciseApi";


const ANY_BODY_PART = { id: "null", name: "Any Body Part" }; 
const ANY_TYPE = "Any Type";


const FilterExercises = ({RenderExercises, handleSelect}) => {
    const [visibleExercises, setVisibleExercises] = useState(null);
    const [input, setInput] = useState("");
    const [selectedBodyPart, setSelectedBodyPart] = useState(ANY_BODY_PART.id); 

    const [selectedType, setSelectedType] = useState(ANY_TYPE);

    const {data: exercises} = useQuery('exercise', exerciseApi.getExercises);

    const queryClient = useQueryClient();
    const bodyParts = queryClient.getQueryData("bodyParts") || [];
    const type = queryClient.getQueryData("type");

    useEffect(() => {
        // console.log("Selected Body Part ID:", selectedBodyPart);
        // console.log("Exercises:", exercises);
    
        let filteredExercises = (exercises || []).filter(obj => {
            // console.log(`Exercise name: ${obj.name}, Body Parts: ${obj.body_parts}`);
            return obj.name.toLowerCase().includes(input.toLowerCase());
        });

        if (selectedBodyPart !== "null") { 
            filteredExercises = filteredExercises.filter(obj => {
                const includesBodyPart = obj.body_parts.includes(Number(selectedBodyPart));
                // console.log(`Filtering ${obj.name}, Parts: ${obj.body_parts} Includes Body Part ${selectedBodyPart}:`, includesBodyPart);
                return includesBodyPart;
            });
        }
    
        if (selectedType !== ANY_TYPE) {
            filteredExercises = filteredExercises.filter(obj => obj.type === selectedType);
        }
    
        // console.log("Filtered Exercises:", filteredExercises);
        setVisibleExercises(filteredExercises);
    }, [exercises, input, selectedBodyPart, selectedType]);
    
    return (
        <>
            <Form>
                <Form.Group className="mb-2" controlId="search-exercises">
                    <InputGroup>
                        <InputGroup.Text>
                            <i className="fas fa-search"/>
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="Search"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                        />
                    </InputGroup>
                </Form.Group>
            </Form>

            <Row>
                <Col>
                <BodypartDropdown
                        dropdownId="dropdown-body-part"
                        label={ANY_BODY_PART.name} 
                        items={bodyParts} 
                        selected={selectedBodyPart}
                        onSelect={(selectedId) => setSelectedBodyPart(selectedId)} 
                        itemLabelField="name" 
                        itemValueField="id" 
                    />
                </Col>
                <Col>
                    <ExerciseDropdown
                        dropdownId="dropdown-type"
                        label={ANY_TYPE}
                        items={type}
                        selected={selectedType}
                        onSelect={setSelectedType}
                    />
                </Col>
            </Row>

            <RenderExercises exercises={visibleExercises} handleSelect={handleSelect}/>
        </>
    );
};

export default FilterExercises;