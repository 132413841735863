import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import DurationPicker from './DurationPicker';

const DurationPickerModal = ({ onDurationChange, initialDuration }) => {
  const [showModal, setShowModal] = useState(false);
  // Use initialDuration prop to set initial state, converting format if necessary
  const [duration, setDuration] = useState(initialDuration || `00°00'00"`);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleDurationChange = (newDuration) => {
    // Assuming newDuration is in the format "00:00:00"
    const [hours, minutes, seconds] = newDuration.split(':');
    const formattedDuration = `${hours}°${minutes}'${seconds}"`;
    setDuration(formattedDuration);
    onDurationChange(formattedDuration);
  };

  return (
    <>
      <Form.Control
        type="text"
        value={duration}
        className='bs-body-bg px-1 small-label-text'
        style={{ paddingTop: '4px', paddingBottom: '4px' }}
        onClick={handleOpenModal}
        readOnly 

      />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Duration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DurationPicker onDurationChange={handleDurationChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DurationPickerModal;
