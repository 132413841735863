import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Accordion, Card, Button, Modal } from 'react-bootstrap';
import QRCode from 'qrcode.react';

import WorkoutItem from '../NewWorkout/WorkoutItem';
import { useQuery } from 'react-query';
import * as api from "../../../api/folderApi";
import { encodeFolderShareTokenToURL } from "../../../utils/encoding";
import { useDeleteFolderMutation, useRenameFolderMutation } from '../../../hooks/folderHooks';
import { useConfirmationModalContext } from '../../Common/ConfirmationModalContext';

const FolderList = ({ handleDeleteWorkout, openQRModal, handleGenerateAndCopyLink }) => {
    const { data: folders, isLoading, isError, error } = useQuery('folders', api.getFolders);
    const [expandedFolders, setExpandedFolders] = useState([]);
    const [editedFolderNames, setEditedFolderNames] = useState({});
    const [showQRModal, setShowQRModal] = useState(false);
    const [qrValue, setQrValue] = useState('');

    const { mutate: deleteFolderMutation } = useDeleteFolderMutation();
    const { mutate: renameFolderMutation } = useRenameFolderMutation();

    const { requestConfirm } = useConfirmationModalContext();
    const handleDeleteFolder = (folderId) => {
        requestConfirm({
            message: "Are you sure you want to delete this folder?",
            onConfirm: () => {
                deleteFolderMutation(folderId)
            }
        });
    };

    const toggleFolder = (folderIndex) => {
        const currentIndex = expandedFolders.indexOf(folderIndex);
        const newExpandedFolders = [...expandedFolders];

        if (currentIndex === -1) {
            newExpandedFolders.push(folderIndex);
        } else {
            newExpandedFolders.splice(currentIndex, 1);
        }

        setExpandedFolders(newExpandedFolders);
    };

    const handleSaveFolderName = (folderId, newName) => {
        setEditedFolderNames((prev) => {
            const updatedNames = { ...prev };
            delete updatedNames[folderId];
            return updatedNames;
        });
        renameFolderMutation({ folderId: folderId, newName: newName })
    };

    const handleInputKeyPress = (e, folderId) => {
        if (e.key === 'Enter') {
            handleSaveFolderName(folderId, editedFolderNames[folderId]);
        }
    };

    const handleGenerateToken = (folderId) => {
        api.generateTokenForFolder(folderId)
            .then(data => {
                var share_url = encodeFolderShareTokenToURL(data.token);
                openFolderQRModal(share_url);
                navigator.clipboard.writeText(share_url).then(() => {
                    toast.success('Folder share link copied to clipboard');
                });
            })
            .catch(error => {
                console.error('Error generating token:', error);
                toast.error('Failed to generate folder share link');
            });
    };

    const openFolderQRModal = async (link) => {
        if (link) {
            setQrValue(link);
            setShowQRModal(true);
        } else {
            toast.error("Error generating QR code.");
        }
    };
    

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error.message}</div>;
    return (
        <Accordion defaultActiveKey="" className="mb-3">
            {folders.map((folder, folderIndex) => (
                <Card key={folder.id} className='my-2'>
                    <Card.Header style={{ cursor: 'pointer' }} onClick={() => toggleFolder(String(folderIndex))}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div key={String(folderIndex)} className="d-flex align-items-center">
                                {!expandedFolders.includes(String(folderIndex)) ? <i className='fas fa-folder' aria-label='hidden' /> : <i className='fa-regular fa-folder-open' aria-label='hidden' />}
                                {" "}
                                {editedFolderNames[folder.id] !== undefined ? (
                                    <input
                                        key={String(folderIndex)}
                                        type="text"
                                        value={editedFolderNames[folder.id]}
                                        onChange={(e) =>
                                            setEditedFolderNames({ ...editedFolderNames, [folder.id]: e.target.value })
                                        }
                                        onBlur={() => handleSaveFolderName(folder.id, editedFolderNames[folder.id])}
                                        onKeyPress={(e) => handleInputKeyPress(e, folder.id)}
                                    />
                                ) : (
                                    <span
                                        onClick={() =>
                                            setEditedFolderNames({ ...editedFolderNames, [folder.id]: folder.name })
                                        }>
                                        {folder.name}
                                    </span>
                                )}
                            </div>
                            <div className="d-flex align-items-center">
                                <Button variant="light" className="mx-1 p-1" onClick={(e) => { e.stopPropagation(); handleGenerateToken(folder.id); }}>
                                    <i className="fa fa-qrcode" aria-hidden="true" />
                                </Button>
                                <Button variant="light" className="mx-1 p-1" onClick={(e) => { e.stopPropagation(); handleDeleteFolder(folder.id); }}>
                                    <i className="fa fa-trash" aria-hidden="true" />
                                </Button>
                                {!expandedFolders.includes(String(folderIndex)) ? <span className="badge text-bg-primary mx-2"><i className="fa fa-angle-down" aria-hidden="true" /></span> : <span className="badge text-bg-primary mx-2"><i className="fa fa-angle-up" aria-hidden="true" /></span>}
                            </div>
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={String(folderIndex)} in={expandedFolders.includes(String(folderIndex))}>
                        <Card.Body>
                        <div className='text-secondary'>Accessible by: {folder.accessible_by_users.join(', ')}</div>
                            <Droppable key={String(folderIndex)} droppableId={`${folder.id}`}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {folder.workouts.map((workout, index) => (
                                            <Draggable key={workout.id} draggableId={`workout-${workout.id}`} index={index}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <WorkoutItem
                                                            workout={workout}
                                                            handleDeleteWorkout={handleDeleteWorkout}
                                                            openQRModal={openQRModal}
                                                            handleGenerateAndCopyLink={handleGenerateAndCopyLink}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            ))}
        <Modal show={showQRModal} onHide={() => setShowQRModal(false)} centered>
            <Modal.Body className="d-flex justify-content-center">
                <QRCode value={qrValue} size={360} />
            </Modal.Body>
        </Modal>
        </Accordion>
        
    );
};

export default FolderList;
