import axiosInstance from "./instances/axiosInstance";
import {apiRoutes} from "../utils/routes";

export const getUsersWhosWorkoutHistoryCanBeViewed = () => {
    let url = apiRoutes.userworkouthistory;
    // console.log(`Fetching workout history with URL: ${url}`); // Log the URL being requested
    return axiosInstance.get(url)
        .then(response => {
            // console.log('Workout history data received:', response.data); // Log the data received
            return response.data;
        })
        .catch(error => {
            console.error('Error fetching user list:', error); // Log any errors
            throw error; // Rethrow the error to ensure it can be caught or handled by the caller
        });
};

export const getViewersOfUserWorkoutHistory = () => {
    let url = `${apiRoutes.userworkouthistory}viewers/`;
    return axiosInstance.get(url)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error('Error fetching viewer list:', error);
            throw error;
        });
};


export const revokeWorkoutHistoryViewingAccess = (username) => {
    let url = `${apiRoutes.userworkouthistory}revoke/`;
    return axiosInstance.post(url, {username})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error('Error revoking access:', error);
            throw error;
        });
};