import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import { Row, Col, Container } from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "react-query";
import React, { useState, useEffect } from 'react';
import {ToastContainer} from "react-toastify";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile";
import Workout from "./components/Workout";
import Exercises from "./components/Exercises";
import WorkoutHistory from "./components/History";
import ProtectedRoute from "./components/ProtectedRoute";
import UserProfileFetcher from './components/UserProfileFetcher';
import {updateWeightUnit} from "./redux/slices/authSlice";
import {pageRoutes} from "./utils/routes";
import { logoutUser } from './redux/slices/authSlice';
import { refreshAccessTokenIfNeeded } from './api/authApi';
import * as exerciseApi from "./api/exerciseApi";
import * as profileApi from "./api/profileApi";
import { ConfirmationModalProvider } from "./components/Common/ConfirmationModalContext";
import PrivacyPolicy from "./components/Legales/PrivacyPolicy";
import TermsAndConditions from "./components/Legales/TermsAndConditions";

const InstallAppSection = () => {
    return (
        <div className="install-app-section border-top">
            <Row>
                <Col>
                    <div className="px-4 mt-3 pb-0 mb-0">
                        <p className="pb-0 mb-0">Install this app on your phone: Tap <i class="fa-solid fa-arrow-up-from-bracket"></i> and then Add to homescreen.</p>
                        <h2 className="text-center p-0 m-0"><i class="fa-solid fa-arrow-down"></i></h2>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const App = () => {
    const dispatch = useDispatch();
    const authenticated = useSelector((state) => state.auth.authenticated);
    const [isInitialized, setIsInitialized] = useState(false);
    const [showInstallMessage, setShowInstallMessage] = useState(false); // State for install message


    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') || 'dark';
        document.documentElement.setAttribute('data-bs-theme', savedTheme);
        if (!localStorage.getItem('theme')) {
            localStorage.setItem('theme', 'dark');
        }

        const initializeAuth = async () => {
            if (authenticated) {
                try {
                    await refreshAccessTokenIfNeeded();
                } catch {
                    dispatch(logoutUser());
                }
            }
            setIsInitialized(true);
        };

        initializeAuth();

        // Detects if device is on iOS 
        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            console.log("userAgent", userAgent);
            return /iphone|ipad|ipod/.test( userAgent );
        }
        // Detects if device is in standalone mode
        const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

        // Check for iOS and standalone mode on component mount
        if (isIos() && !isInStandaloneMode()) {
            console.log("isIos()", isIos());
            console.log("isInStandaloneMode()", isInStandaloneMode());
            setShowInstallMessage(true);
        }

    }, [dispatch, authenticated]);

    // Define queries after the application has been initialized and user is authenticated
    useQuery('bodyParts', exerciseApi.getBodyParts, { staleTime: Infinity, enabled: isInitialized && authenticated });
    useQuery('type', exerciseApi.getType, { staleTime: Infinity, enabled: isInitialized && authenticated });
    useQuery('profileExists', profileApi.getOrCreateProfile, { staleTime: Infinity, enabled: isInitialized && authenticated });
    useQuery('profile', profileApi.getProfile, {
        enabled: isInitialized && authenticated,
        onSuccess: (data) => {
            if (data && data["weight_system"]) {
                dispatch(updateWeightUnit(data["weight_system"]));
            }
        },
        onError: (error) => {
            console.error("Failed to fetch profile:", error);
        }
    });

    useQuery('bodyParts', exerciseApi.getBodyParts, {staleTime: Infinity, enabled: authenticated});
    useQuery('type', exerciseApi.getType, {staleTime: Infinity, enabled: authenticated});
    useQuery('profileExists', profileApi.getOrCreateProfile, {staleTime: Infinity, enabled: authenticated});
    useQuery('profile', profileApi.getProfile, { 
        enabled: authenticated,
        onSuccess: (data) => {
            if (data && data["weight_system"]) { // Check if data and weight_system exist
                dispatch(updateWeightUnit(data["weight_system"]));
            } else {
                console.error("Profile data is missing 'weight_system' property:", data);
            }
        },
    });

    return (
        <div className="App">
            <ConfirmationModalProvider>
            <Router>
                <UserProfileFetcher />
                <NavigationBar/>
                <Container className="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto main container-md" fluid="md">
                    <Routes>
                        <Route index path={pageRoutes.login} element={<Login/>}/>
                        <Route path={pageRoutes.register} element={<Register/>}/>
                        <Route path={pageRoutes.profile} element={<ProtectedRoute><Profile/></ProtectedRoute>}/>
                        <Route path={pageRoutes.workout} element={<ProtectedRoute><Workout/></ProtectedRoute>}/>
                        <Route path={pageRoutes.history} element={<ProtectedRoute><WorkoutHistory/></ProtectedRoute>}/>
                        <Route path={pageRoutes.exercises} element={<ProtectedRoute><Exercises/></ProtectedRoute>}/>
                        <Route
                            path="/terms-and-conditions"
                            element={<TermsAndConditions />}
                        />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="*" element={authenticated ? <Navigate to={pageRoutes.workout}/> : <Login/>}/>
                    </Routes>
                </Container>
            </Router>
            </ConfirmationModalProvider>
            <Footer/> 
            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* {showInstallMessage && <InstallAppSection />} */}

        </div>
    );
};

export default App;
