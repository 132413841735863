import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";

const ProtectedRoute = ({children}) => {
    const authenticated = useSelector((state) => state.auth.authenticated)
    const { isProfileLoading } = useSelector(state => state.auth);

    if (!authenticated) {
        // Encode the full current location, including the hash, as a base64 string
        const fullUrl = window.location.href;
        const encodedUrl = btoa(fullUrl);
        // Pass the encoded URL as part of the state
        return <Navigate to={`/login?redirect=${encodedUrl}`} replace />;
    }

    if (isProfileLoading) {
        return null;
    } else {
        return children;  // Only render children when not loading and authenticated
    }
};

export default ProtectedRoute;
