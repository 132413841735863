import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const TempoPicker = ({ onTempoChange }) => {
  const [down, setDown] = useState('0');
  const [pause, setPause] = useState('0');
  const [up, setUp] = useState('0');

  const handleTempoChange = (setFunction, value, unit) => {
    // Update the state based on which unit (Down, pause, up) is being changed
    setFunction(value);

    // Calculate the new Tempo based on the unit being changed
    const newDown = unit === 'down' ? value : down;
    const newPause = unit === 'pause' ? value : pause;
    const newUp = unit === 'up' ? value : up;

    // Call the onTempoChange prop with the updated Tempo
    onTempoChange(`${newDown}-${newPause}-${newUp}`);
  };

  return (
    <Row>
      <Col xs="auto" className="d-flex align-items-center">
        <Form.Control as="select" value={down} onChange={(e) => handleTempoChange(setDown, e.target.value, 'down')}>
          {Array.from({ length: 9 }, (_, i) => i.toString().padStart(1, '0')).map((down) => (
            <option key={down} value={down}>{down}</option>
          ))}
        </Form.Control>
        <span className="ms-1">down</span>
      </Col>
      <Col xs="auto" className="d-flex align-items-center">
        <Form.Control as="select" value={pause} onChange={(e) => handleTempoChange(setPause, e.target.value, 'pause')}>
          {Array.from({ length: 9 }, (_, i) => i.toString().padStart(1, '0')).map((pause) => (
            <option key={pause} value={pause}>{pause}</option>
          ))}
        </Form.Control>
        <span className="ms-1">pause</span>
      </Col>
      <Col xs="auto" className="d-flex align-items-center">
        <Form.Control as="select" value={up} onChange={(e) => handleTempoChange(setUp, e.target.value, 'up')}>
          {Array.from({ length: 9 }, (_, i) => i.toString().padStart(1, '0')).map((up) => (
            <option key={up} value={up}>{up}</option>
          ))}
        </Form.Control>
        <span className="ms-1">up</span>
      </Col>
    </Row>
  );
};

export default TempoPicker;
