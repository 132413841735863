import Dropdown from "react-bootstrap/Dropdown";

const BodypartDropdown = ({
    dropdownId,
    label,
    items,
    selected,
    onSelect,
    itemLabelField = "id", // Default to "label" if not specified
    itemValueField = "name" // Default to "value" if not specified
}) => {
    return (
        <Dropdown className="d-grid gap-1" onSelect={(eventKey) => onSelect(eventKey)}>
            <Dropdown.Toggle id={dropdownId} variant="secondary">
                {items.find(item => item[itemValueField] === Number(selected))?.[itemLabelField] || "Any Body Part"}
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark">
                <Dropdown.Item eventKey = {"null"} active={selected === label}>{label}</Dropdown.Item>
                <Dropdown.Divider/>
                {items.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item[itemValueField]} active={selected === item[itemValueField]}>
                        {item[itemLabelField]}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default BodypartDropdown;
