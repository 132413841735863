import React from "react";
import { Link } from "react-router-dom";

// Maybe we should load that from JSON file so it's easier to mantain
const TermsAndConditions = () => {
  return (
    <div className="terms-conditions-container">
      <h1>Terms and Conditions</h1>
      <br></br>

      <p>
        The policy below applies to the BEOLIFT application, produced and owned
        by Beoflow LTD. BEOLIFT is the exclusive property of Beoflow LTD and
        operates in accordance with its Terms and Conditions policy.
      </p>
      <hr></hr>

      <h2>General Assumptions</h2>
      <p>
        Beoflow is a biotracking and aerobic load management system that
        utilizes integrations from pre-existing biotracker platforms to provide
        users with a comprehensive view of their health and fitness data.
      </p>

      <h2>Definitions</h2>
      <p>
        <strong>"Beoflow"</strong> refers to the company Beoflow and its
        biotracking system, including the website, mobile app, customer support
        systems, and all associated services.
      </p>
      <p>
        <strong>"User"</strong> means any individual who uses Beoflow for
        personal use.
      </p>
      <p>
        <strong>"Coach"</strong> refers to any professional who uses the service
        to administer health and fitness protocols to an individual.
      </p>
      <p>
        <strong>"The Service"</strong> refers to the health and fitness
        application platform known as Beoflow.
      </p>
      <p>
        <strong>"Personal Information" or "PI"</strong> means any information
        that can be used to identify an individual, including name, email
        address, and billing information.
      </p>
      <p>
        <strong>"Consumer Health Information" or "CHI"</strong> refers to any
        telemetry data collected by a supported biotracker and processed by
        Beoflow.
      </p>

      <h2>Acceptance of Terms</h2>
      <p>
        By agreeing to our terms and conditions you agree to the following.
        Please be aware that failure to comply with the terms and conditions of
        the platform may result in charges made against you or removal from the
        service.
      </p>

      <p>
        Reporting Violations: You agree to promptly report any violations of
        these terms and conditions by other users or third parties to Beoflow.
      </p>

      <h2>Usage of service</h2>
      <p>
        In order to make use of the Service an account creation step is required
        via the Website in which You will register yourself as a user. As part
        of the registration process, you will provide the Service with
        Registration Data. By registering and providing Registration Data, you
        confirm that all information that you have provided is accurate and you
        retain the responsibility to keep your registration data current for as
        long as you make use of the Service. You accept that registration via
        the Website is mandatory for use of the service and you acknowledge that
        it is your responsibility to protect your Registration Data to prevent
        unauthorized access to Your account.
      </p>

      <p>
        The Service allows You to view your CHI and to receive updates
        containing fragments of your CHI via third-party messaging services. You
        understand that the Service uses third-party vendors and hosting
        partners to provide the necessary hardware, software, networking,
        storage, and related technology required to run the Service. You
        understand that the quality and accuracy of the service provided is only
        as reliable and consistent as the data recorded. Support for the Service
        is only available in English, via email or WhatsApp messaging. –  We
        provide no guarantees for the level of support unless stipulated by an
        additional contract or Service Level Agreement.
      </p>

      <h2>Intellectual Property</h2>
      <p>
        Beoflow leverages proprietary, in-house software and algorithms to
        provide personalized health and fitness updates. Consequently, the
        Service is safeguarded by the following intellectual property rights.
      </p>
      <ul>
        <li>
          <strong>Resell or Sub-License:</strong> <br></br>
          <p>
            You may not resell, sublicense, lease, rent, or otherwise monetize
            the Service or any part thereof without explicit written consent
            from Beoflow.
          </p>
        </li>
        <li>
          <strong>Reverse Engineering:</strong> <br></br>
          <p>
            You shall not attempt to reverse engineer, decompile, disassemble,
            or otherwise derive the source code or underlying algorithms of the
            Service or any components thereof.
          </p>
        </li>
        <li>
          <strong>Sale or Duplication:</strong> <br></br>
          <p>
            You are prohibited from selling, duplicating, copying, or
            reproducing the Service, in whole or in part, for commercial
            purposes or otherwise, without prior written authorization from
            Beoflow.
          </p>
        </li>
        <li>
          <strong>Reproduction or Recreation:</strong> <br></br>
          <p>
            You shall not attempt to reproduce or recreate, in any form, the
            Service or any part of the Service without the explicit written
            consent of Beoflow.
          </p>
        </li>
        <li>
          <strong>Unauthorized Access:</strong> <br></br>
          <p>
            You shall not access, use, or attempt to access or use the Service
            in any manner not expressly authorized by Beoflow.
          </p>
        </li>
        <li>
          <strong>Circumventing Security:</strong> <br></br>
          <p>
            You shall not attempt to circumvent, disable, or disrupt any
            security features or measures implemented within the Service.
          </p>
        </li>
        <li>
          <strong>Violation of Intellectual Property Rights:</strong> <br></br>
          <p>
            You shall not use the Service in a manner that infringes upon the
            intellectual property rights, including but not limited to
            copyright, trademark, and patents, of Beoflow or any third party.
          </p>
        </li>
        <li>
          <strong>Violation of Applicable Laws:</strong> <br></br>
          <p>
            You shall not use the Service for any unlawful or fraudulent
            purposes, and you agree to comply with all applicable local, state,
            national, and international laws and regulations.
          </p>
        </li>
        <li>
          <strong>Data Mining and Scraping:</strong> <br></br>
          <p>
            You are prohibited from using automated methods, including data
            mining, web scraping, or bots, to extract or collect data from the
            Service without prior written consent from Beoflow.
          </p>
        </li>
        <li>
          <strong>Interference with Service:</strong> <br></br>
          <p>
            You shall not engage in activities that may interfere with or
            disrupt the functionality, availability, or performance of the
            Service, including but not limited to Distributed Denial of Service
            (DDoS) attacks.
          </p>
        </li>
        <li>
          <strong>Modification of Service:</strong> <br></br>
          <p>
            You shall not modify, adapt, or create derivative works based on the
            Service or any portion of it without explicit written authorization
            from Beoflow.
          </p>
        </li>
        <li>
          <strong>Commercial Use Restrictions:</strong> <br></br>
          <p>
            If you are granted permission to use the Service for commercial
            purposes, you agree to comply with any additional terms and
            conditions specified by Beoflow, including payment and licensing
            terms.
          </p>
        </li>
        <li>
          <strong>Harmful Code:</strong> <br></br>
          <p>
            You are prohibited from uploading, posting, or transmitting any
            malicious code, viruses, worms, or other harmful software that may
            damage or interfere with the Service's operation or the data of
            other users.
          </p>
        </li>
        <li>
          <strong>Export Control Laws:</strong> <br></br>
          <p>
            You agree not to use the Service in violation of any export control
            laws and regulations and shall not export, re-export, or transfer
            the Service to restricted parties or organizations without proper
            authorization from Beoflow.
          </p>
        </li>
        <li>
          <strong>Continuous Compliance:</strong> <br></br>
          <p>
            You acknowledge that these restrictions apply at all times,
            including during the use of the Service and after the termination of
            your account.
          </p>
        </li>
        <li>
          <strong>Consequences of Violation:</strong> <br></br>
          <p>
            Any violation of these prohibitions may result in the immediate
            termination of your access to the Service and may subject you to
            legal actions, including but not limited to civil, criminal, and
            injunctive remedies.
          </p>
        </li>
      </ul>
      <h2>Integrations</h2>
      <p>
        When a user grants the Service permission to transmit your CHI through a
        third-party channel, it signifies the user's agreement and understanding
        that following this transmission, Beoflow will no longer be responsible
        for the aforementioned CHI data. Furthermore, it's important to note
        that Beoflow is not accountable for the data security of third-party
        channels or services receiving your transmitted CHI. In addition to
        this, our Service may feature links to third-party websites or services
        that are not under the ownership or control of the Company. In such
        cases, the Company lacks control over these entities and assumes no
        responsibility for their content, privacy policies, or practices. You
        also recognize and accept that the Company bears no responsibility or
        liability, whether direct or indirect, for any damage or loss resulting
        from or allegedly connected to your use of or reliance on content,
        goods, or services offered on or through these third-party websites or
        services. For your own protection, we strongly recommend that you
        carefully review the terms and privacy policies of any third-party
        websites or services you choose to visit.
      </p>

      <h2>Other connected services - Stripe/Jira</h2>

      <h3>Stripe - Online Payment Provider</h3>
      <p>
        Beoflow integrates with Stripe, a third-party payment processing
        service, to facilitate secure and efficient transactions for our
        services. While we strive to provide a seamless and secure payment
        experience for our users, it is essential to clarify that Beoflow
        assumes no liability for any issues, disputes, or consequences that may
        arise as a result of using Stripe.
      </p>
      <p>
        Stripe's services are subject to their own terms of service, privacy
        policies, and security measures, which are entirely independent of
        Beoflow. Any concerns or disputes related to payments, billing, or
        financial transactions should be directed to Stripe and resolved in
        accordance with their policies and procedures.
      </p>
      <p>
        By using Beoflow's services and choosing to make payments through
        Stripe, you acknowledge and agree that Beoflow is not responsible for,
        and disclaims any liability for, any losses, damages, or issues that may
        occur during or as a result of using Stripe. Beoflow is committed to
        providing a secure and reliable platform, but we cannot control or
        guarantee the performance or actions of third-party service providers.
      </p>
      <p>
        We encourage all users to familiarize themselves with Stripe's terms and
        conditions to ensure a clear understanding of the payment process and
        associated responsibilities. Should you encounter any difficulties with
        Stripe, we recommend reaching out to their customer support for
        assistance.
      </p>
      <p>
        Please note that this disclaimer is meant to clarify Beoflow's limited
        role in facilitating payments and to ensure that our users are aware of
        the distinct responsibilities and policies of Stripe. Beoflow remains
        committed to providing exceptional service, and your understanding of
        this limitation is appreciated.
      </p>

      <h3>Jira</h3>

      <p>
        Beoflow utilizes Jira for its customer support portal. Please be aware
        that Beoflow bears no responsibility for communications received through
        our Jira help page. Any messages or feedback sent via Jira are managed
        by Jira independently, and Beoflow disclaims any liability for their
        content or consequences. We encourage users to follow Jira's terms of
        service and privacy policies. For assistance with Jira-related issues,
        please contact Jira's customer support.
      </p>

      <h2>Responsibilities for all users </h2>
      <p>
        Accurate Information: Provide accurate personal information, and health
        and fitness metrics (CHI), when setting up your profile and using the
        app.
      </p>

      <p>
        Privacy Settings: Take responsibility for configuring your privacy
        settings and sharing your data with other users or the public as
        desired.
      </p>
      <p>
        Data Security: Safeguard your login credentials, and take necessary
        precautions to prevent unauthorized access to your account and personal
        information. This includes selecting a strong password and refraining
        from sharing your login credentials with others.
      </p>
      <p>
        Respect Others: Interact with other users respectfully and refrain from
        any offensive, harmful, or discriminatory behavior in comments,
        messages, or other interactions within the app.
      </p>
      <p>
        Compliance with Laws: Abide by all relevant local and national laws
        while using the app, especially concerning the storage and sharing of
        health data.
      </p>
      <p>
        Data Accuracy: Regularly review and update your health and fitness data
        to maintain its accuracy, reflecting your current status.
      </p>
      <p>
        Data Sharing: If you decide to share your health and fitness data with
        healthcare providers or other authorized individuals, ensure it is done
        securely and in compliance with applicable regulations.
      </p>
      <p>
        Responsible Feedback: Provide constructive and respectful feedback to
        Beoflow support and report any app-related issues promptly.
      </p>
      <p>
        Informed Decision-Making: Understand that the information provided by
        Beoflow is for informational purposes only and should not replace
        professional medical advice. Consult with a healthcare professional for
        specific health concerns.
      </p>
      <p>
        Appropriate Use: Use Beoflow for its intended purpose, which is health
        and fitness tracking, and avoid any misuse or activities that might harm
        the app's integrity or reputation.
      </p>
      <p>
        App Updates: Keep your Beoflow app updated to access new features,
        security improvements, and performance enhancements.
      </p>
      <p>
        Consent for Data Collection: Understand and consent to the collection
        and processing of your health and fitness data by Beoflow for the
        purpose of the app's functionalities.
      </p>
      <p>
        Data Backups: Regularly back up your data stored within the app to
        prevent any loss of valuable information.
      </p>
      <p>
        Account Termination: Follow the app's procedures for account deletion or
        deactivation if you choose to stop using Beoflow.
      </p>
      <p>
        User Community Guidelines: Familiarize yourself with and adhere to
        Beoflow's community guidelines, which include respectful interactions,
        no spamming, and no sharing of inappropriate content.
      </p>
      <p>
        Legal Compliance: Comply with copyright and intellectual property laws
        when uploading and sharing content, ensuring you have the right to share
        any images, text, or other materials.
      </p>
      <p>
        Medical Disclaimer: Acknowledge that Beoflow does not provide medical
        advice or diagnosis. Any information and guidance provided by the app
        are for informational purposes and not a substitute for professional
        medical advice.
      </p>
      <p>
        Reporting Issues: Report any technical issues, bugs, or security
        vulnerabilities to Beoflow's support team for prompt resolution.
      </p>
      <p>
        Unauthorized Activities: Refrain from any unauthorized access or
        attempts to manipulate the app's code, security systems, or data.
      </p>
      <p>
        Data Sharing Consent: Seek appropriate consent from individuals if you
        intend to share their health or fitness data within the app, respecting
        their privacy and rights.
      </p>
      <p>
        Safety Precautions: Prioritize your safety and the safety of others when
        participating in fitness challenges or competitions organized through
        Beoflow, and follow safe practices and recommendations.
      </p>
      <p>
        Continuous Learning: Stay informed about new app features, updates, and
        changes by regularly reviewing notifications, emails, or in-app
        announcements.
      </p>

      <h2>User data</h2>
      <p>
        User data provided to us will be processed in accordance with our
        Privacy Policy. We highly recommend that all users review our Privacy
        Policy to understand how their data is collected, processed, and
        protected. Our commitment to data privacy and security is of utmost
        importance, and we strive to maintain transparency in our data
        processing practices. Please click{" "}
        <Link to="/privacy-policy">here</Link> to view our privacy policy.
      </p>

      <h2>Severability</h2>
      <p>
        If any provision of these Terms is held to be invalid or unenforceable,
        such provision shall be struck from these Terms and the remaining
        provisions shall remain in full force and effect.
      </p>

      <h2>Notices</h2>
      <p>
        Beoflow may modify these Terms of Service at any time by posting the
        revised Terms of Service on the Beoflow website. Your continued use of
        Beoflow after any such modification constitutes your acceptance of the
        revised Terms of Service.
      </p>

      <h2>Limitation of liability</h2>
      <p>
        The user explicitly acknowledges that Beoflow assumes no liability for
        adverse outcomes arising from the utilization of its platform.
        Furthermore, the user recognizes and accepts that Beoflow bears no
        responsibility for any unfavorable consequences, outcomes, or injuries
        resulting from decisions made based on the health and fitness
        interventions provided by the service. Please note that this extends to
        any changes made to users' integrated apps, even if these changes
        enhance the functionality or quality of the service.
      </p>

      <h2>Disclaimer of Warranties</h2>
      <p>
        Beoflow is provided on an "as is" and "as available" basis. Beoflow
        makes no warranties, express or implied, with respect to Beoflow or the
        information or services provided through Beoflow.
      </p>

      <h2>Disputes</h2>
      <p>
        The user acknowledges that Beoflow reserves the exclusive right to
        resolve and make determinations on the outcome of any and all disputes
        related to the use of its services unless explicitly directed otherwise
        by a legally recognized court of law. This provision is a fundamental
        part of our Terms of Service, and by using our services, you agree to
        abide by this dispute resolution process.
      </p>

      <h2>Terms of payment </h2>
      <p>The user acknowledges the following terms of payment: </p>
      <p>
        All payments made to Beoflow are considered final and non-refundable
        unless otherwise explicitly agreed upon by Beoflow or mandated by a
        court of law.
      </p>
      <p>
        Recurring subscriptions will be collected on a monthly basis by our
        trusted online payment provider, Stripe, using the payment method
        provided by the user at the time of subscription.
      </p>
      <p>
        In the event that a user fails to complete a payment by the due date,
        Beoflow reserves the right to suspend all account services until the
        outstanding payment has been received. During this suspension, access to
        the platform and associated services may be restricted until the payment
        is successfully processed.
      </p>
      <p>
        Any disputes related to payments or billing issues should be promptly
        communicated to our customer support team. We encourage users to resolve
        such matters amicably through our support channels before pursuing any
        other actions.
      </p>
      <p>
        Beoflow retains the right to update its pricing and payment terms, and
        any changes will be communicated to users in advance, allowing them to
        make informed decisions regarding their subscriptions and payments.
      </p>
      <p>
        By using Beoflow's services, you signify your understanding and
        acceptance of these payment terms
      </p>

      <h2>Contact</h2>
      <p>Dan TBA</p>
    </div>
  );
};

export default TermsAndConditions;