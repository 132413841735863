// EditExerciseModal/index.js
import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import Select from "../NewExerciseModal/Select";
import SelectBodyPart from "../NewExerciseModal/SelectBodyPart";
import { updateExercise, getBodyParts, getType } from "../../../api/exerciseApi";

const EditExerciseModal = ({ show, onHide, exercise, onExerciseUpdated }) => {
  const [exerciseName, setExerciseName] = useState("");
  const [selectedBodyPart, setSelectedBodyPart] = useState({ id: 0, name: 'Full Body' });
  const [selectedType, setSelectedType] = useState("");
  const [exerciseDescription, setExerciseDescription] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedSharing, setSelectedSharing] = useState("Private"); 
  const [bodyParts, setBodyParts] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    const fetchBodyPartsAndSetSelected = async () => {
        const fetchedBodyParts = await getBodyParts();
        setBodyParts(fetchedBodyParts);
        
        if (exercise) {
            setExerciseName(exercise.name);
            // Assume exercise.body_parts is an array of IDs
            const bodyPartObject = fetchedBodyParts.find(bp => bp.id === exercise.body_parts[0]) || { id: 0, name: 'Full Body' };
            setSelectedBodyPart(bodyPartObject);

            setSelectedType(exercise.type);
            setExerciseDescription(exercise.description);
            setSelectedSharing(exercise.sharing);
        }
    };

    fetchBodyPartsAndSetSelected();
    getType().then((data) => setTypes(data));
}, [exercise]);


  const updateExerciseMutation = useMutation(
    (data) => updateExercise(exercise.id, data, (progress) => setUploadProgress(progress)),
    {
      onSuccess: () => {
        toast.success("Exercise updated successfully!");
        clear();
        onHide();
        onExerciseUpdated(); // Invoke the callback
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to update exercise.");
      },
    }
  );

  const clear = () => {
      setExerciseName("");
      setSelectedBodyPart({ id: 0, name: 'Full Body' }); 
      setSelectedType("");
      setExerciseDescription("");
      setSelectedSharing("Private"); 
  };

  const handleSave = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", exerciseName);
    if (selectedBodyPart && selectedBodyPart.id) {
        formData.append("body_parts", selectedBodyPart.id.toString());
    } else if (bodyParts.length > 0) {
        formData.append("body_parts", bodyParts[0].id.toString());
    }        
    formData.append("type", selectedType);
    formData.append("description", exerciseDescription);
    formData.append("sharing", selectedSharing);
    formData.append("viewable", true);

    updateExerciseMutation.mutate(formData);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="editExerciseModal">
      <Form onSubmit={handleSave}>
        <Modal.Header closeButton>
          <Modal.Title id="editExerciseModal">Edit Exercise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="inputExerciseName" className="mb-3">
            <Form.Label>Exercise name</Form.Label>
            <Form.Control type="text" value={exerciseName} onChange={(e) => setExerciseName(e.target.value)} required />
          </Form.Group>

          <SelectBodyPart label="Body Part" options={bodyParts} 
                    selectValue={selectedBodyPart}         
                    onSelect={(e) => {
                        const selectedOption = bodyParts.find(bp => bp.id.toString() === e.target.value);
                        setSelectedBodyPart(selectedOption || { id: 0, name: 'Full Body' });
                    }}  />


          <Select label="Type" options={types} selectValue={selectedType} onSelect={(e) => setSelectedType(e.target.value)} />

          <Form.Group controlId="inputExerciseDescription" className="mb-3">
            <Form.Label>Exercise description</Form.Label>
            <Form.Control as="textarea" value={exerciseDescription} onChange={(e) => setExerciseDescription(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="inputExerciseSharing" className="mb-3">
            <Form.Label>Who can see this exercise?</Form.Label>
            <Form.Select value={selectedSharing} onChange={(e) => setSelectedSharing(e.target.value)}>
              <option value="Private">Only Me</option>
              <option value="Shareable">Shareable</option>
              <option value="Public">Public</option>
            </Form.Select>
          </Form.Group>

          {uploadProgress > 0 && <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditExerciseModal;
