import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    folders:[],
    noFolderWorkouts: []
};

export const folderSlice = createSlice({
    name: 'folders',
    initialState,
    reducers: {
        addWorkoutToFolder: (state, action) => {
            const { folderId, workout } = action.payload;
            console.log('folderId',folderId)
            console.log('workout',workout)
            const folder = state.folders.find(f => f.id === folderId);
            console.log('folder', folder)
            if (folder) {
                folder.workouts.push(workout); // Push the whole workout object, not just the ID
            }
        },
        removeWorkoutFromFolder: (state, action) => {
            const { folderId, workoutId } = action.payload;
            const folder = state.folders.find(f => f.id === folderId);
            if (folder) {
                folder.workouts = folder.workouts.filter(w => w.id !== workoutId);
            }
        },
        moveWorkout: (state, action) => {
            const { sourceId, destinationId, workoutId } = action.payload;
            const sourceFolder = state.folders.find(f => f.id === sourceId);
            const destinationFolder = state.folders.find(f => f.id === destinationId);
            const workout = sourceFolder.workouts.find(w => w.id === workoutId);
            sourceFolder.workouts = sourceFolder.workouts.filter(w => w.id !== workoutId);
            destinationFolder.workouts.push(workout);
        },
    }
});

export const {
    addWorkoutToFolder,
    removeWorkoutFromFolder,
    moveWorkout,
} = folderSlice.actions;

export default folderSlice.reducer;
