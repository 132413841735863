import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import TempoPicker from './TempoPicker';

const TempoPickerModal = ({ onTempoChange, initialTempo }) => {
  const [showModal, setShowModal] = useState(false);
  // Use initialTempo prop to set initial state, converting format if necessary
  const [tempo, setTempo] = useState(initialTempo || `2-0-1`);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleTempoChange = (newTempo) => {
    const [down, pause, up] = newTempo.split('-');
    const formattedTempo = `${down}-${pause}-${up}`;
    setTempo(formattedTempo);
    onTempoChange(formattedTempo);
  };

  return (
    <>
      <Form.Control
        type="text"
        value={tempo}
        className='bs-body-bg px-1 small-label-text'
        style={{ paddingTop: '4px', paddingBottom: '4px' }}
        onClick={handleOpenModal}
        readOnly 

      />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Tempo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TempoPicker onTempoChange={handleTempoChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TempoPickerModal;
