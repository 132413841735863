
const Footer = () => {


    return (
        <footer className="bg-body pt-1">
            <div className="text-center">
                <span>{process.env.REACT_APP_NAME} 💪
                </span>
            </div>
        </footer>
    );
};

export default Footer;
