import axiosInstance from "./instances/axiosInstance";
import {apiRoutes} from "../utils/routes";

export const getProfile = () => {
  return axiosInstance.get(apiRoutes.profile)
    .then(response => {
      if (response.headers['content-type'].includes('application/json')) {
        // console.log(`Retrieved profile data:`, response.data);
        return response.data[0];
      } else {
        console.error('Unexpected response type:', response.headers['content-type']);
        console.log(`Full response:`, response); // Log the full response
        console.log(`Response data:`, response.data); // Log the full response
        throw new Error('Unexpected response type');
      }
    })
    .catch(error => {
      console.error(`Failed to get profile`, error);
      throw error;
    });
};

export const getOrCreateProfile = () => axiosInstance.get(apiRoutes.profile + 'get-or-create/').then(response => response.data[0]);
export const updateProfile = (data) => axiosInstance.post(apiRoutes.profile, data).then(response => response.data);