// ConfirmationModalContext.js
import React, { createContext, useContext, useState } from 'react';
import ConfirmationModal from './ConfirmationModal';

const ConfirmationModalContext = createContext();

export const useConfirmationModalContext = () => useContext(ConfirmationModalContext);

export const ConfirmationModalProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [modalProps, setModalProps] = useState({});

    const requestConfirm = (props) => {
        setModalProps(props);
        setIsVisible(true);
    };

    const handleConfirm = () => {
        if (modalProps.onConfirm) modalProps.onConfirm();
        setIsVisible(false);
    };

    const handleCancel = () => {
        if (modalProps.onCancel) modalProps.onCancel();
        setIsVisible(false);
    };

    return (
        <ConfirmationModalContext.Provider value={{ requestConfirm }}>
            {children}
            <ConfirmationModal
                show={isVisible}
                onHide={handleCancel}
                onConfirm={handleConfirm}
                title={modalProps.title || "Confirm"}
                message={modalProps.message || "Are you sure?"}
            />
        </ConfirmationModalContext.Provider>
    );
};
