import {useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import { useMutation, useQueryClient } from 'react-query';
import {Formik} from "formik";
import {toast} from "react-toastify";
import * as yup from "yup";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import * as api from "../../api/authApi";
import {login} from "../../redux/slices/authSlice";
import {pageRoutes} from "../../utils/routes";

const schema = yup.object().shape({
    email: yup.string().label("Email").email().required(),
    username: yup
        .string()
        .label("Username")
        .required()
        .min(3)
        .max(32)
        .matches(/[a-zA-Z]/, "Username can only contain Latin letters."),

    password: yup
        .string()
        .label("Password")
        .required()
        .min(8)
        .max(32)
        .matches(/^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/, "Password can only contain Latin letters, numbers, and underscores. "),
    
    termsAndConditions: yup
          .boolean()
          .required("You must accept the terms and conditions")
          .oneOf([true], "You must accept the terms and conditions"),
        
    privacyPolicy: yup
          .boolean()
          .required("You must accept the privacy policy")
          .oneOf([true], "You must accept the privacy policy"),
});


const Register = () => {
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch()
    const queryClient = useQueryClient(); // Assuming you've imported useQueryClient from 'react-query'

    // Your existing useState and useDispatch setup
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get("redirect");
    
    const registerMutation = useMutation(api.register, {
        onSuccess: (data) => {
            dispatch(login(data)); 
            
            queryClient.invalidateQueries('profileExists'); 
            queryClient.invalidateQueries('profile');
            queryClient.invalidateQueries('type'); 
            queryClient.invalidateQueries('bodyParts'); 

            toast.success("You have registered!");

            // Check if a "redirect" query parameter was passed and redirect accordingly
            if (redirectUrl) {
                const decodedUrl = atob(redirectUrl);
                window.location.replace(decodedUrl); // Redirects the user to the original intended location
            } else {
                navigate(pageRoutes.workout); // Navigates to the workout page if no specific redirect was specified
            }
        },
        onError: (error) => {
            toast.error("Could not register!");
        },
    });
    

    return (
        <div>
            <Stack gap={2} className="col-md-10 mx-auto">
            <h1 className="text-center m-3">{process.env.REACT_APP_NAME} 💪</h1>
                <div className="badge rounded-pill bg-warning mb-2">This app is in beta</div>                
                <h2 className="text-center">Register</h2>

                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => registerMutation.mutate(values)}
                    initialValues={{username: "", email: "", password: "", privacyPolicy: false, termsAndConditions: false}}
                >
                    {({handleSubmit, handleChange, values, touched, errors}) => (
                        <Form noValidate onSubmit={handleSubmit}>

                            <Form.Group className="mb-2" controlId="signupEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="name@example.com"
                                    autoComplete="on"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={touched.email && errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="signupUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    placeholder="Username"
                                    autoComplete="off"
                                    value={values.username}
                                    onChange={handleChange}
                                    isInvalid={touched.username && errors.username}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.username}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="signupPassword">
                                <Form.Label>Password</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        placeholder="Password"
                                        autoComplete="off"
                                        value={values.password}
                                        onChange={handleChange}
                                        isInvalid={touched.password && errors.password}
                                    />
                                    <InputGroup.Text>
                                        <i
                                            onClick={() => setShowPassword(!showPassword)}
                                            className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
                                        />
                                    </InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            {/* Terms and Conditions Checkbox */}
                            <Form.Group className="mb-3" controlId="termsAndConditions">
                                <div className="d-flex align-items-center">
                                <Form.Check
                                    type="checkbox"
                                    name="termsAndConditions"
                                    className="me-2"
                                    onChange={handleChange}
                                    isInvalid={!!errors.termsAndConditions}
                                    id="termsAndConditions"
                                />
                                <Form.Label
                                    htmlFor="termsAndConditions"
                                    className={`mb-0 ${
                                    errors.termsAndConditions ? "text-danger" : ""
                                    }`}
                                >
                                    I accept the{" "}
                                    <Link to="/terms-and-conditions" target="_blank">
                                    Terms and Conditions
                                    </Link>
                                </Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                {errors.termsAndConditions}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Privacy Policy Checkbox */}
                            <Form.Group className="mb-3" controlId="privacyPolicy">
                                <div className="d-flex align-items-center">
                                <Form.Check
                                    type="checkbox"
                                    name="privacyPolicy"
                                    className="me-2"
                                    onChange={handleChange}
                                    isInvalid={!!errors.privacyPolicy}
                                    id="privacyPolicy"
                                />
                                <Form.Label
                                    htmlFor="privacyPolicy"
                                    className={`mb-0 ${
                                    errors.privacyPolicy ? "text-danger" : ""
                                    }`}
                                >
                                    I accept the{" "}
                                    <Link to="/privacy-policy" target="_blank">
                                    Privacy Policy
                                    </Link>
                                </Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                {errors.privacyPolicy}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <div className="col-md-12 text-center">
                                <Button variant="primary" size="lg" type="submit">
                                    Register
                                </Button>
                            </div>

                        </Form>)}
                </Formik>

                <div className="col-md-12 text-center">
                    Already a member? <Link to={pageRoutes.login}>Log In</Link>
                </div>

            </Stack>
        </div>);
};

export default Register;
