import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import { useMutation, useQueryClient } from 'react-query';
import { deleteExercise } from '../../../api/exerciseApi'; // Adjust the path as necessary
import { toast } from 'react-toastify';
import { useConfirmationModalContext } from "../../Common/ConfirmationModalContext";
import EditExerciseModal from '../EditExerciseModal'; // Adjust the path as necessary


const linkify = (text) => {
    // Ensure text is a string and not empty or undefined
    if (typeof text !== 'string' || !text) {
        console.error('linkify was passed a non-string argument:', text);
        return <React.Fragment>{text}</React.Fragment>;
    }

    // Regex to match URLs
    const urlRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    // Split text into parts to isolate URLs
    const parts = text.split(urlRegex);

    // Map through the parts and transform URLs into anchor tags
    return parts.map((part, index) => {
        if (part.match(urlRegex)) {
            // This part is a URL, extract the domain
            const domain = new URL(part).hostname.replace('www.', '');

            // Wrap the URL in an anchor tag with custom link text
            return (
                <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                    {`${domain} link`}
                </a>
            );
        } else {
            // This part is not a URL, return it as is
            return part;
        }
    });
};


const ExerciseDetailsModal = ({ exercise, show, onHide }) => {
    const queryClient = useQueryClient();
    const [showEditModal, setShowEditModal] = useState(false);
    const [exerciseUpdated, setExerciseUpdated] = useState(false);

    // Function to be passed to EditExerciseModal
    const handleExerciseUpdated = () => {
        setExerciseUpdated(true);
    };

    useEffect(() => {
        if (exerciseUpdated) {
            queryClient.invalidateQueries("exercise");
            setExerciseUpdated(false); 
            onHide();
        }
    }, [exerciseUpdated, exercise.id, onHide, queryClient]); // Include onHide and queryClient in the dependencies


    const handleEdit = () => {
        setShowEditModal(true);
    };
    const handleCloseEditModal = () => {
        setShowEditModal(false);
    };
    

    const mutation = useMutation(() => deleteExercise(exercise.id), {
        onSuccess: () => {
            queryClient.invalidateQueries('exercise'); // Adjust if your query keys differ
            onHide(); // Close the modal after successful deletion
            toast.success('Exercise deleted successfully');
        },
        onError: (error) => {
            toast.error(`Error deleting exercise: ${error.message}`);
        }
    });
    const { requestConfirm } = useConfirmationModalContext();
    const handleDelete = () => {
        requestConfirm({
            message: "Are you sure you want to delete this exercise from the library?",
            onConfirm: () => mutation.mutate(),
        });
    };

    const getBadgeVariant = (sharing) => {
        switch (sharing) {
            case 'Public':
                return 'success';
            case 'Connections':
                return 'warning';
            case 'Private':
                return 'secondary';
            default:
                return 'primary';
        }
    };
    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {exercise.name} ({exercise.type})
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <h6>Body Parts: {exercise.body_parts}</h6> */}
                <div className={`badge rounded-pill bg-${getBadgeVariant(exercise.sharing)} mb-2`}>{exercise.sharing}</div>
                <div className="text-secondary"> Description</div> 
                <p className='dont-break-out'>{linkify(exercise.description)}</p>
                {exercise.images && exercise.images.length > 0 && (
                    <Carousel>
                        {exercise.images.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img
                                    className="d-block w-100"
                                    src={image.image} // Assuming 'image.url' is how your image URL is accessed
                                    alt={`Exercise ${index + 1}`}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                )}
                {exercise.videos && exercise.videos.length > 0 && (
                    exercise.videos.map((video, index) => (
                        <div key={index} className="mt-3">
                            <video controls width="100%">
                                <source src={video.video} type="video/mp4" /> {/* Assuming 'video.url' is how your video URL is accessed */}
                                Your browser does not support this video format.
                            </video>
                        </div>
                    ))
                )}
            </Modal.Body>
            <Modal.Footer>
                {exercise.profile_id && (
                    <Button variant="danger" onClick={handleDelete}>Delete Exercise</Button>
                )}
                {exercise.profile_id && (
                <Button variant="info" onClick={handleEdit}>Edit Exercise</Button>
                )}
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
            <EditExerciseModal 
                show={showEditModal} 
                onHide={handleCloseEditModal} 
                exercise={exercise}
                onExerciseUpdated={handleExerciseUpdated} // Pass the callback
            />
        </Modal>
        
    );
};


export default ExerciseDetailsModal;