import { createSlice } from '@reduxjs/toolkit';
import { queryClient } from "../../api/instances/queryClient";

const initialState = {
    name: '',
    workoutStarted: false,
    exercises: [],
    maxSupersetNumber: 0, // Initializing maxSupersetNumber
};

const initialExerciseDetails = (superset_number = null) => ({
    sets: 1,
    reps: 1,
    superset_number,
});

export const workoutSlice = createSlice({
    name: 'workout',
    initialState,
    reducers: {
        initializeWorkout: (state, action) => {
            console.log('Initializing workout:', action.payload);
            state.workoutStarted = true;
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.exercises = action.payload.workout_exercises;
            state.maxSupersetNumber = Math.max(...state.exercises.map(exercise => {
                return Math.max(...exercise.workout_exercise_details.map(detail => detail.superset_number || 0));
            }));
        },
        startWorkout: (state) => {
            state.workoutStarted = true;
        },
        clearWorkout: () => {
            return initialState;
        },
        addExercises: (state, action) => {
            const { exercises: exerciseIds, superset_number } = action.payload;
            const exercises = queryClient.getQueryData("exercise");
            const exercisesToAdd = exerciseIds.map(id => {
                const exercise = exercises.find(exercise => exercise.id === id);
                exercise["workout_exercise_details"] = [initialExerciseDetails(superset_number)];
                exercise["exercise"] = exercise.id;
                exercise["notes"] = exercise["notes"] || ''; // Ensure notes is initialized
                return exercise;
            });
            state.exercises.push(...exercisesToAdd);
            if (superset_number !== null) {
                state.maxSupersetNumber += 1;
            }
        },
        addSupersetSet: (state, action) => {
            const { supersetNumber } = action.payload;
            const exercisesToDuplicate = state.exercises.filter(exercise =>
                exercise.workout_exercise_details.some(detail => detail.superset_number === supersetNumber)
            );
            const pattern = [...new Set(exercisesToDuplicate.map(exercise => exercise.id))];
            const lastSupersetIndex = state.exercises.findIndex((exercise, index, exercises) => {
                return exercise.workout_exercise_details.some(detail =>
                    detail.superset_number === supersetNumber) && (index === exercises.length - 1 || exercises[index + 1].workout_exercise_details.every(detail => detail.superset_number !== supersetNumber));
            });
        
            const generateUniqueId = (() => {
                let currentMaxId = Math.max(...state.exercises.map(ex => ex.id));
                return () => ++currentMaxId;
            })();
        
            const reversedExercises = state.exercises.slice().reverse();
            const clonedExercises = pattern.map(exerciseId => {
                const exerciseToClone = reversedExercises.find(exercise => exercise.id === exerciseId);
                return {
                    ...exerciseToClone,
                    id: generateUniqueId(),  // Generate a new unique id
                    workout_exercise_details: exerciseToClone.workout_exercise_details.map(detail => ({
                        ...detail,
                    }))
                };
            });
        
            console.log(clonedExercises);
            state.exercises.splice(lastSupersetIndex + 1, 0, ...clonedExercises);
        },        
        deleteExercise: (state, action) => {
            state.exercises.splice(action.payload, 1);
            state.maxSupersetNumber = Math.max(...state.exercises.map(exercise => {
                return Math.max(...exercise.workout_exercise_details.map(detail => detail.superset_number || 0));
            }));
        },
        addExerciseDetails: (state, action) => {
            const { exerciseIndex, newDetail } = action.payload;
            const lastDetail = state.exercises[exerciseIndex]["workout_exercise_details"].slice(-1)[0];
            if (newDetail) {
                state.exercises[exerciseIndex]["workout_exercise_details"].push(newDetail);
            } else if (lastDetail) {
                state.exercises[exerciseIndex]["workout_exercise_details"].push({ ...lastDetail });
            } else {
                state.exercises[exerciseIndex]["workout_exercise_details"].push(initialExerciseDetails());
            }
        },
        updateExerciseDetails: (state, action) => {
            const { exerciseIndex, index, name, value } = action.payload;
            state.exercises[exerciseIndex]["workout_exercise_details"][index][name] = value;
        },
        updateExerciseNote: (state, action) => {
            const { exerciseIndex, newNote } = action.payload;
            if (state.exercises[exerciseIndex]) {
                state.exercises[exerciseIndex]["notes"] = newNote;
            } else {
                console.error(`Exercise at index ${exerciseIndex} does not exist.`);
            }
        },
        deleteExerciseDetails: (state, action) => {
            const { exerciseIndex, index } = action.payload;
            state.exercises[exerciseIndex]["workout_exercise_details"].splice(index, 1);
        },
        reorderExercises: (state, action) => {
            const { startIndex, endIndex } = action.payload;
            const startExercise = state.exercises[startIndex];
            const supersetNumber = getSupersetNumber(startExercise.workout_exercise_details);
        
            if (supersetNumber !== null) {
                if (isStartOfSuperset(state.exercises, startIndex, supersetNumber)) {
                    // Move the entire superset as a block
                    const supersetExercises = state.exercises.filter(exercise =>
                        exercise.workout_exercise_details.some(detail => detail.superset_number === supersetNumber)
                    );
        
                    // Remove all superset exercises
                    state.exercises = state.exercises.filter(exercise =>
                        !exercise.workout_exercise_details.some(detail => detail.superset_number === supersetNumber)
                    );
        
                    // Determine the correct insertion index
                    let insertionIndex = endIndex;
                    if (insertionIndex > startIndex) {
                        insertionIndex -= supersetExercises.length;
                    }
        
                    // Insert superset exercises at the new position
                    state.exercises.splice(insertionIndex, 0, ...supersetExercises);
                } else {
                    // Reorder within the same superset
                    const supersetIndices = getSupersetIndices(state.exercises, supersetNumber);
                    const startSupersetIndex = supersetIndices[0];
                    const endSupersetIndex = supersetIndices[supersetIndices.length - 1];
        
                    if (endIndex <= endSupersetIndex && endIndex >= startSupersetIndex) {
                        // Find all exercises in the superset that are the same
                        const exerciseId = state.exercises[startIndex].exercise;
                        const matchingIndices = supersetIndices.filter(
                            index => state.exercises[index].exercise === exerciseId
                        );
        
                        // Calculate the relative movement within the superset
                        const relativeMovement = endIndex - startIndex;
        
                        // Move all matching exercises by the same relative movement
                        matchingIndices.forEach(index => {
                            const [removed] = state.exercises.splice(index, 1);
                            const newIndex = index + relativeMovement;
                            state.exercises.splice(newIndex, 0, removed);
                        });
                    }
                }
            } else {
                // Prevent non-superset exercises from being dropped within a superset
                const destinationSupersetNumber = getSupersetNumber(state.exercises[endIndex]?.workout_exercise_details);
                const destinationSupersetNumberMin = getSupersetNumber(state.exercises[endIndex-1]?.workout_exercise_details);
                const destinationSupersetNumberPlus = getSupersetNumber(state.exercises[endIndex+1]?.workout_exercise_details);

                if ( destinationSupersetNumber === null && destinationSupersetNumberMin == null) {
                    // Regular reordering for non-superset exercises
                    const [removed] = state.exercises.splice(startIndex, 1);
                    state.exercises.splice(endIndex, 0, removed);
                }

                // this allows moving things to just before a superset
                if (destinationSupersetNumber !== null && destinationSupersetNumber === destinationSupersetNumberPlus && destinationSupersetNumber !== destinationSupersetNumberMin && startIndex > endIndex) {
                    // Regular reordering for non-superset exercises
                    console.log("This 1")
                    const [removed] = state.exercises.splice(startIndex, 1);
                    state.exercises.splice(endIndex, 0, removed);
                }

                // this allows moving thins to just after a superset
                if (destinationSupersetNumber !== null && destinationSupersetNumber === destinationSupersetNumberMin && destinationSupersetNumber !== destinationSupersetNumberPlus) {
                    // Regular reordering for non-superset exercises
                    console.log("This 2")
                    const [removed] = state.exercises.splice(startIndex, 1);
                    state.exercises.splice(endIndex + 1, 0, removed);
                }

                // this allows moving thins to just after a superset
                if (destinationSupersetNumber === null && destinationSupersetNumber !== destinationSupersetNumberMin && destinationSupersetNumber === destinationSupersetNumberPlus) {
                    // Regular reordering for non-superset exercises
                    console.log("This 3")
                    const [removed] = state.exercises.splice(startIndex, 1);
                    state.exercises.splice(endIndex, 0, removed);
                }

            }
        },            
        removeSuperset: (state, action) => {
            const { supersetNumber } = action.payload;
            state.exercises = state.exercises.filter(exercise => 
                !exercise.workout_exercise_details.some(detail => detail.superset_number === supersetNumber)
            );
            state.maxSupersetNumber = Math.max(...state.exercises.map(exercise => {
                return Math.max(...exercise.workout_exercise_details.map(detail => detail.superset_number || 0));
            }));
        },
        replaceExercisesInDetail: (state, action) => {
            const exercises = queryClient.getQueryData("exercise");
            const { supersetNumber, exerciseIndex, newExercises } = action.payload;
            // find the value of exercise for state.exercises[exerciseIndex]
            var new_exercise_id = newExercises[0] // limiting to 1 for now
            var new_exercise_definition = exercises.find(exercise => exercise.id === new_exercise_id);
            var existing_exercise = state.exercises[exerciseIndex].exercise;

            if (supersetNumber !== null) {
                state.exercises.forEach((exercise, index) => {
                    if (exercise.workout_exercise_details.some(detail => detail.superset_number === supersetNumber && exercise.exercise === existing_exercise)) {
                        state.exercises[index].exercise = new_exercise_id;
                        state.exercises[index].name = new_exercise_definition.name;
                        state.exercises[index].type = new_exercise_definition.type;
                    }
                });
            } else {
                state.exercises[exerciseIndex].exercise = new_exercise_id;
                state.exercises[exerciseIndex].name = new_exercise_definition.name;
                state.exercises[exerciseIndex].type = new_exercise_definition.type;
            }
        },
    },
});

const getSupersetNumber = (exerciseDetails) => {
    try {
        const detailWithSuperset = exerciseDetails.find(detail => detail.superset_number != null);
        return detailWithSuperset ? detailWithSuperset.superset_number : null;
    } catch (error) {
        console.log("Error in getSupersetNumber: ", error);
        return null;
    }
};

const isStartOfSuperset = (exercises, index, supersetNumber) => {
    if (index === 0) return true;
    const currentSupersetNumber = getSupersetNumber(exercises[index].workout_exercise_details);
    const previousSupersetNumber = getSupersetNumber(exercises[index - 1].workout_exercise_details);
    return currentSupersetNumber !== previousSupersetNumber;
};

const getSupersetIndices = (exercises, supersetNumber) => {
    return exercises
        .map((exercise, index) => index)
        .filter(index => getSupersetNumber(exercises[index].workout_exercise_details) === supersetNumber);
};

export const {
    initializeWorkout,
    startWorkout,
    clearWorkout,
    addExercises,
    deleteExercise,
    addExerciseDetails,
    updateExerciseDetails,
    updateExerciseNote,
    deleteExerciseDetails,
    addSupersetSet,
    reorderExercises,
    removeSuperset,
    replaceExercisesInDetail,
} = workoutSlice.actions;

export default workoutSlice.reducer;
