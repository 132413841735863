import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    authenticated: false,
    profile: null,
    weightUnit: null,
    isProfileLoading: false,  // Tracking profile loading state
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.authenticated = true;
            localStorage.setItem("accessToken", action.payload.access);
            localStorage.setItem("refreshToken", action.payload.refresh);
        },
        updateProfile: (state, action) => {
            state.profile = action.payload;
            state.isProfileLoading = false;  // End loading on successful profile update
        },
        startProfileLoading: (state) => {
            state.isProfileLoading = true;  // Start loading when fetching profile
        },
        endProfileLoading: (state) => {
            state.isProfileLoading = false;  // Explicitly end loading without updating profile
        },
        logoutUser: (state) => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            state.authenticated = false;
            state.profile = null;
            state.weightUnit = null;
            state.isProfileLoading = false;
        },
        updateWeightUnit: (state, action) => {
            state.weightUnit = action.payload;
        },
    },
});

export const {
    login,
    updateProfile,
    logoutUser,
    updateWeightUnit,
    startProfileLoading,
    endProfileLoading
} = authSlice.actions;

export default authSlice.reducer;
