import React from "react";
// import { Link } from "react-router-dom";

// Maybe we should load that from JSON file so it's easier to mantain
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <br></br>
      <h2>1. General assumptions</h2>
      <p>
        This policy applies to the BEOLIFT application, produced and owned by
        Beoflow LTD. BEOLIFT is the exclusive property of Beoflow LTD and
        operates in accordance with Beoflow's practices and privacy policy seen
        below.
      </p>
      <hr></hr>
      <p>
        Beoflow is an online health and fitness platform that centralizes
        third-party biomonitoring platforms and devices. 
        {/* It does this via website xxxxx and the iOS app xxx and the Android app xxx. */}
      </p>
      <p>
        To provide its services, Beoflow collects and analyzes specific user
        data, as described in this privacy policy.
      </p>
      <p>
        We recommend that you read this policy carefully before using Beoflow
        services and check this policy regularly for changes and amendments. If
        you have any questions about this privacy policy, please feel free to
        contact us through our <strong>help center</strong>.
      </p>
      <p>
        <strong>
          By registering with Beoflow and using Beoflow's services, you agree to
          the collection, processing, and use of your personal data as set out
          in this Privacy Policy.
        </strong>
      </p>
      <p>
        Please be aware that users may withdraw consent, however, refusal of
        this policy will result in removal from the platform.
      </p>

      <h2>2. How Do We Collect Your Data?</h2>
      <p>
        You directly provide Beoflow with most of the data we collect. We
        collect and process data when you:
      </p>

      <ul>
        <li>Register online with our service.</li>
        <li>Voluntarily complete a recovery or activity survey.</li>
        <li>Use or view our website via your browser's cookies.</li>
        <li>
          Contact our sales or support teams via our integrated chat bubble.
        </li>
        <li>Change or update your user settings.</li>
        <li>
          Connect a biotracker or notification integration to our account.
        </li>
      </ul>

      <h2>3. What Data Do We Collect?</h2>
      <h3>Personal Information ("PI")</h3>
      <p>
        Personal identification data, such as the user's name, billing address,
        email address, telephone number, and demographic information, including
        age, gender, and country of origin. Please note that a record of any
        updates or changes made to this data will be retained. For further
        information relating to how this data is processed please see section 5.
      </p>

      <h3>Consumer Health Information (“CHI”)</h3>
      <p>
        Personal Health Data is collected from third-party peripheral services,
        such as heart rate meters or sleep trackers. This includes health and
        fitness data collected by supported biomonitors, such as heart rate,
        respiratory rate, heart rate variability, sleep duration and quality,
        muscle pain, cadence, power, distance, and speed. CHI also includes any
        data provided through the Service's forms or Bot interactions. For
        further information relating to how this data is processed please see
        section 5.
      </p>

      <h3>Derivative Data</h3>
      <p>
        The information our servers automatically collect when the user accesses
        the Beoflow website or app, such as your IP address, browser type, your
        operating system, your access times, session keys, and the pages you
        have viewed directly before and after accessing the site. This data is
        collected solely for site analytics.{" "}
      </p>

      <h3>Financial Data</h3>
      <p>
        Financial information related to the user's payment method (e.g., valid
        credit card number, card brand, expiration date) is voluntarily provided
        by the user upon account creation. All financial information is stored
        and processed by our online payment provider, Stripe. You are encouraged
        to review their privacy policy and contact them directly for responses
        to payment-related questions.
      </p>

      <h3>Personal Device Data</h3>
      <p>
        Device information about the user's mobile device and chosen biotracker
        integration, including device ID, model, manufacturer, and information
        about the location of your device.
      </p>

      <h3>Third-Party Data</h3>
      <p>
        Information from third parties, such as personal information or network
        friends, if you connect your account to the third party and grant the
        site permission to access this information.
      </p>

      <h3>Data From Contests, Giveaways, and Surveys</h3>
      <p>
        Personal and other information you may provide when entering contests or
        giveaways and/or responding to surveys.
      </p>

      <h3>Consent Data</h3>
      <p>
        In compliance with legal obligations, we are mandated to maintain a
        comprehensive record of all instances wherein users grant their consent
        to our privacy policy, cookies, terms of service, agent access, and
        biotracker integration.
      </p>

      <h2>4. Why does Beoflow collect your data</h2>
      <p>
        Beoflow gathers the data mentioned in section 3 with the aim of
        enhancing and delivering our highly personalized service. This data
        includes Personal Information ("PI") and Consumer Health Data ("CHI"),
        which are essential for us to provide our services through both the
        website and the app, serving the purposes outlined in sections 5 and 6.
      </p>

      <h2>5. Use of your PI information</h2>
      <p>
        Acquiring personal information pertaining to your profile allows us to
        deliver a seamless, productive, and tailored experience. Specifically,
        we will utilize the data collected about you through the Site or Service
        to:
      </p>

      <ul>
        <li>Administer promotions and contests.</li>
        <li>Assist law enforcement and respond to subpoenas.</li>
        <li>
          Compile anonymous and aggregated statistical data and analysis for
          internal use or publication with third parties.
        </li>
        <li>Create, manage, and perform maintenance on your account.</li>
        <li>
          Deliver targeted advertising, coupons, newsletters, and other
          information regarding promotions and the Site and Service to you.
        </li>
        <li>Contact you regarding your account or order.</li>
        <li>Enable user-to-user communications.</li>
        <li>
          Fulfill and manage purchases, orders, payments, and other transactions
          related to the Site and Service.
        </li>
        <li>
          Generate a personal profile for you to make future visits to the Site
          and Service more personalized.
        </li>
        <li>
          Increase the efficiency and operation of the Site and the Service.
        </li>
        <li>
          Monitor and analyze usage and trends to improve your experience with
          the Site and the Service.
        </li>
        <li>Notify you of updates to the Site and the Service.</li>
        <li>Offer new products, services, and recommendations to you.</li>
        <li>Perform other necessary business activities.</li>
        <li>
          Prevent fraudulent transactions, monitor against theft, and protect
          against criminal activity.
        </li>
        <li>Process payments and refunds.</li>
        <li>
          Request feedback and contact you about your use of the Site and the
          Service.
        </li>
        <li>Resolve disputes.</li>
        <li>Troubleshoot problems.</li>
        <li>Respond to product and customer service requests.</li>
      </ul>

      <h2>6. Use of Your Consumer Health Data</h2>
      <p>
        The use of our Service and associated products is dependent on
        collecting your Consumer Health Data (CHI) through integrations with
        third-party peripherals, services, and software. As part of the Service,
        you have the option to authorize us to access your CHI from third-party
        sources through an authentication process to provide Beoflow with
        personalized health and fitness data. This data will be used for the
        following purposes:
      </p>
      <ul>
        <li>
          To deliver personalized health and fitness metrics, we utilize the
          biometric data collected from each user, ensuring tailored insights
          for individual health profiles.
        </li>
        <li>
          To enhance the user experience, our platform utilizes biometric data
          recorded to provide personalized health recommendations and
          interventions. These suggestions are tailored to the individual's
          unique health profile, offering valuable insights and guidance to
          support their well-being and fitness journey.
        </li>
        <li>
          To compile anonymized and aggregated data for the purpose of
          conducting analysis and research. It is important to emphasize that
          this data does not pertain to any particular individual or reveal any
          personally identifiable information. Its sole purpose is to enable the
          observation of trends across our platform.
        </li>
        <li>
          We may analyze de-identified CHI and PI for the purpose of improving
          the Website and the Service. We will limit our use of de-identified PI
          and CHI to that allowed under DPR laws.
        </li>
      </ul>

      <h2>7. Who Can See Your CHI and PI Data</h2>
      <p>
        Your data is intended solely for your personal viewing only; however, in
        certain circumstances, the following parties may be given access to your
        data.
      </p>
      <ul>
        <li>
          Beoflow representatives, including, management, customer support,
          developers, and data scientists may access your CHI and PI data in
          circumstances where there is a legitimate need in order to maintain
          the function of the service. We maintain logs of employee access to
          user data and regularly review this data for abnormal activity.{" "}
        </li>
        <li>
          You may voluntarily share your CHI data with your chosen healthcare
          professional, coach, trainer, or a third party of your choosing. Be
          aware that Beoflow does not guarantee security and takes no
          responsibility for the sharing of CHI data after being shared with
          other users of the service. This applies to both healthcare
          professionals and fellow users.
        </li>
        <li>
          Data will be anonymized and aggregated to conduct platform-wide
          analytics and observations. Please note that your individual CHI or PI
          will not be shared. Only cumulative statistics will be used to publish
          results relating to general trends across the user base.
        </li>
      </ul>

      <h2>8. How Do We Store Your Data?</h2>
      <p>
        User data is securely stored on privately hosted servers and is
        protected by a number of security measures. Some of those methods
        include blind encryption, secure socket layers, authentication
        processes, and non-disclosure agreements. If a data breach comes to our
        attention, we will investigate the extent of the breach and inform you
        promptly via email.
      </p>
      <p>
        <strong>
          Please be aware that your data will be retained as long as you
          maintain an account with us.
        </strong>
      </p>

      <h2>9. Marketing</h2>
      <p>
        Upon account creation, the user can opt-in to receive marketing
        communications tailored to their preferences. The user can opt out of
        receiving marketing communications from us at any time by clicking the
        unsubscribe link located at the bottom of our marketing emails.
      </p>
      <p>
        We also use the user's personal data to tailor the marketing
        communications we send. We may send different types of marketing
        communications depending on your interests and activity data.
      </p>
      <p>
        We will never share the user's personal data with third parties for
        marketing purposes without user consent.
      </p>

      <h2>10. What Are Your Data Protection Rights</h2>
      <p>
        Beoflow would like to make sure you are fully aware of all your data
        protection rights. Every user is entitled to the following:
      </p>
      <ul>
        <li>
          The right to access – You have the right to request Beoflow for copies
          of your personal data. We may charge you a small fee for this service.
        </li>
        <li>
          The right to rectification – You have the right to request that
          Beoflow correct any information you believe is inaccurate. You also
          have the right to request Beoflow to complete the information you
          believe is incomplete.
        </li>
        <li>
          The right to erasure – You have the right to request that Beoflow
          erase your personal data, under certain conditions.
        </li>
        <li>
          The right to restrict processing – You have the right to request that
          Beoflow restrict the processing of your personal data, under certain
          conditions.
        </li>
        <li>
          The right to object to processing – You have the right to object to
          Beoflow processing your personal data, under certain conditions.
        </li>
        <li>
          The right to data portability – You have the right to request that
          Beoflow transfer the data that we have collected to another
          organization or directly to you, under certain conditions.
        </li>
        <li>
          It is important to note, however, that changes made to the processing
          of your data may compromise the quality of the service provided. If
          you make a request, we have one month to respond to you. If you would
          like to exercise any of these rights, please contact us at our email:
          Call us at: xxxxxxx Or write to us: xxxxxxx Privacy Policies of
          Connected Services The Beoflow website contains links to other
          websites. Our privacy policy applies only to our website, so if you
          click on a link to another website, you should read their privacy
          policy.
        </li>
      </ul>
      <p>
        Call us at: xxxxxxx <br></br> Or write to us: xxxxxxx <br></br> Privacy
        Policies of Connected Services
      </p>
      <p>
        The Beoflow website contains links to other websites. Our privacy policy
        applies only to our website, so if you click on a link to another
        website, you should read their privacy policy.
      </p>

      <h2>11. Changes to Our Privacy Policy</h2>
      <p>
        Beoflow keeps its privacy policy under regular review and places any
        updates on this web page. This privacy policy was last updated on
        26/10/23.
      </p>

      <h2>12. How to Contact Us</h2>
      <p>
        If you have any questions about Beoflow's privacy policy, the data we
        hold on you, or you would like to exercise one of your data protection
        rights, please do not hesitate to contact us.
      </p>
      <p>
        Email us at: [insert email] <br></br> Call us: [insert phone number]
        <br></br> How to Contact the Appropriate Authority
      </p>
      <p>
        Should you wish to report a complaint or if you feel that Beoflow has
        not addressed your concern in a satisfactory manner, you may contact the
        Information Commissioner’s Office using the link below.
      </p>
    </div>
  );
};

export default PrivacyPolicy;