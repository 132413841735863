import { ListGroup, Row, Col } from "react-bootstrap";
import WorkoutExerciseDetails from "./WorkoutExerciseDetails";


const WorkoutExercise = ({exercises}) => {

const isStartOfSuperset = (index) => {
    if (index === 0) return true; // The first item in the list
    const currentSupersetNumber = getSupersetNumber(exercises[index].workout_exercise_details);
    const previousSupersetNumber = getSupersetNumber(exercises[index - 1].workout_exercise_details);
    return currentSupersetNumber !== previousSupersetNumber;
};

const isEndOfSuperset = (index) => {
    if (index === exercises.length - 1) return true; // The last item in the list
    const currentSupersetNumber = getSupersetNumber(exercises[index].workout_exercise_details);
    const nextSupersetNumber = getSupersetNumber(exercises[index + 1].workout_exercise_details);
    return currentSupersetNumber !== nextSupersetNumber;
};

const getSupersetNumber = (exerciseDetails) => {
    // Find the first detail with a superset_number
    const detailWithSuperset = exerciseDetails.find(detail => detail.superset_number != null);
    // console.log("Exercise Details:", exerciseDetails);

    if (detailWithSuperset) {
        // console.log("Superset Number:", detailWithSuperset.superset_number);
        return detailWithSuperset.superset_number; // Return the superset_number
    }

    return null; // Return null if no superset_number is found
};    
const getSupersetClass = (exerciseDetails) => {
    const superset_number = getSupersetNumber(exerciseDetails);
    if (superset_number == null) return "bg-regular"; // Correctly handle null case

    const supersetClass = superset_number % 2 === 0 ? "bg-superset-even" : "bg-superset-odd";
    return supersetClass;
};
return (
        <div className="mt-2">
            <ListGroup variant="flush">
            {exercises.map((exercise, index) => {
                                const isSuperset = getSupersetNumber(exercise.workout_exercise_details) != null;
                                // const supersetNumber = getSupersetNumber(exercise.workout_exercise_details);
                    return (

                    // <ListGroup.Item 
                    // key={exercise.id} 
                    // style={{border:'none'}}
                    // className={`py-0 my-0 ${getSupersetClass(exercise.workout_exercise_details)}`}
                    // >
                    //     {isSuperset && isStartOfSuperset(index) && <h6 className="my-0 py-0">Superset</h6>}

                    //     <h6 className="py-0 my-0">{exercise.name} ({exercise.type})</h6>
                    //     <WorkoutExerciseDetails exerciseDetails={exercise?.["workout_exercise_details"]}/>
                    // </ListGroup.Item>
                    
                    <ListGroup.Item 
                        key={exercise.id} 
                        className={`py-0 my-0 ${getSupersetClass(exercise.workout_exercise_details)} 
                                    ${isSuperset && isStartOfSuperset(index) && 'rounded-top-4'}
                                    ${isSuperset && isEndOfSuperset(index) && 'rounded-bottom-4'}`}
                        style={{ border: 'none' }}
                    >
                        {isSuperset && isStartOfSuperset(index) &&
                            <Row>
                                <Col xs="auto">
                                    <span className="badge text-bg-secondary my-1 py-1">Superset</span>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col>
                                <h6 className="py-0 my-0">{exercise.name} ({exercise.type})</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {exercise?.["workout_exercise_details"]
                                    ?.filter((detail) => detail.notes && detail.notes.trim())
                                    .map((detail, index) => (
                                    <p key={index}><strong>Notes:</strong> {detail.notes}</p>
                                ))}
                                <WorkoutExerciseDetails exerciseDetails={exercise.workout_exercise_details} />
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    
                    )
                          
                })}
            </ListGroup>
        </div>
    );
};

export default WorkoutExercise;
