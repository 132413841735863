import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const FolderNameModal = ({ show, onHide, onSubmit }) => {
    const [folderName, setFolderName] = useState('');

    const handleSubmit = () => {
        onSubmit(folderName);
        onHide(); // Close modal after submission
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create New Folder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="folderName">
                        <Form.Label>Folder Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter folder name"
                            value={folderName}
                            onChange={e => setFolderName(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Create Folder
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FolderNameModal;
