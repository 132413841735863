import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const DurationPicker = ({ onDurationChange }) => {
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');

  const handleDurationChange = (setFunction, value, unit) => {
    // Update the state based on which unit (hours, minutes, seconds) is being changed
    setFunction(value);

    // Calculate the new duration based on the unit being changed
    const newHours = unit === 'hours' ? value : hours;
    const newMinutes = unit === 'minutes' ? value : minutes;
    const newSeconds = unit === 'seconds' ? value : seconds;

    // Call the onDurationChange prop with the updated duration
    onDurationChange(`${newHours}:${newMinutes}:${newSeconds}`);
  };

  return (
    <Row>
      <Col xs="auto" className="d-flex align-items-center">
        <Form.Control as="select" value={hours} onChange={(e) => handleDurationChange(setHours, e.target.value, 'hours')}>
          {Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0')).map((hour) => (
            <option key={hour} value={hour}>{hour}</option>
          ))}
        </Form.Control>
        <span className="ms-1">h</span>
      </Col>
      <Col xs="auto" className="d-flex align-items-center">
        <Form.Control as="select" value={minutes} onChange={(e) => handleDurationChange(setMinutes, e.target.value, 'minutes')}>
          {Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0')).map((minute) => (
            <option key={minute} value={minute}>{minute}</option>
          ))}
        </Form.Control>
        <span className="ms-1">m</span>
      </Col>
      <Col xs="auto" className="d-flex align-items-center">
        <Form.Control as="select" value={seconds} onChange={(e) => handleDurationChange(setSeconds, e.target.value, 'seconds')}>
          {Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0')).map((second) => (
            <option key={second} value={second}>{second}</option>
          ))}
        </Form.Control>
        <span className="ms-1">s</span>
      </Col>
    </Row>
  );
};

export default DurationPicker;
