import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import React, { useState } from 'react';
import { Button, Modal, ToggleButtonGroup, ToggleButton, ListGroup, Spinner, Card, Container, Row, Col } from 'react-bootstrap';
import * as profileApi from "../../api/profileApi";
import * as authApi from "../../api/authApi";
import * as integrationsApi from "../../api/integrationsApi";
import * as api from "../../api/workoutApi";
import * as userWorkoutHistoryApi from "../../api/userworkouthistoryApi";
import { encodeUserWorkoutHistoryShareTokenToURL } from "../../utils/encoding";
import { timestampToString } from "../../utils/helpers";
import { updateProfile, updateWeightUnit } from "../../redux/slices/authSlice";
import QRCode from 'qrcode.react';

const Profile = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const weightUnit = useSelector((state) => state.auth.weightUnit);
    const profileData = queryClient.getQueryData("profile");
    
    const [showQRModal, setShowQRModal] = useState(false);
    const [qrValue, setQrValue] = useState('');
    
    const { data: viewers, isLoading: viewersLoading } = useQuery('viewers', userWorkoutHistoryApi.getViewersOfUserWorkoutHistory);

    const toggleDarkMode = () => {
        const currentTheme = document.documentElement.getAttribute('data-bs-theme');
        const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
        document.documentElement.setAttribute('data-bs-theme', newTheme);
        localStorage.setItem('theme', newTheme);
    };

    const updateProfileMutation = useMutation(profileApi.updateProfile, {
        onSuccess: (data) => {
            const weightSystem = data["weight_system"];
            dispatch(updateProfile(data));
            dispatch(updateWeightUnit(weightSystem));
            queryClient.invalidateQueries("profile");
            toast.success(`Weight unit: ${weightSystem} is saved!`);
        },
        onError: (error) => {
            console.error(error);
            toast.error("Could not update weight unit system!");
        },
    });

    const handleWeightUnitChange = (value) => {
        const payload = { weight_system: value };
        updateProfileMutation.mutate(payload);
    };

    const handleGenerateToken = async () => {
        try {
            const data = await api.generateTokenForuserWorkoutHistory();
            if (data) {
                var share_url = encodeUserWorkoutHistoryShareTokenToURL(data.token);
                openUserQRModal(share_url);
    
                // Check if the Clipboard API is available and the page is in a secure context
                if (navigator.clipboard && window.isSecureContext) {
                    await navigator.clipboard.writeText(share_url);
                    toast.success('Workout history share link copied to clipboard!');
                } else {
                    // Fallback: Prompt the user to manually copy the URL
                    toast.info('Share the link or QR code to grant access to your workout history.');
                    console.warn('Clipboard API is not available or insecure context');
                }
            }
        } catch (error) {
            console.error('Error generating token:', error);
            toast.info('Share the link or QR code to grant access to your workout history.');
        }
    };
    
    const openUserQRModal = async (link) => {
        if (link) {
            setQrValue(link);
            setShowQRModal(true);
        } else {
            toast.error("Error generating QR code.");
        }
    };

    const handleLinkClick = async () => {
        if (navigator.clipboard && window.isSecureContext) {
            try {
                await navigator.clipboard.writeText(qrValue);
                toast.success('Link copied to clipboard!');
            } catch (error) {
                console.error('Error copying link:', error);
                toast.error('Failed to copy link to clipboard');
            }
        } else {
            console.warn('Clipboard API is not available or insecure context');
            toast.info('Clipboard API is not available or insecure context');
        }
    };

    const revokeAccessMutation = useMutation(userWorkoutHistoryApi.revokeWorkoutHistoryViewingAccess, {
        onSuccess: () => {
            queryClient.invalidateQueries('viewers');
            toast.success("Viewer access revoked successfully!");
        },
        onError: (error) => {
            console.error(error);
            toast.error("Could not revoke viewer access!");
        },
    });

    const handleRevokeAccess = (username) => {
        revokeAccessMutation.mutate(username);
    };

    const logoutMutation = useMutation(authApi.blacklist, {
        onSettled: () => {
            dispatch({ type: "CLEAR_SESSION" });
        },
    });

    const { data: integrations, isLoading: isLoadingIntegrations, refetch: refetchIntegrations } = useQuery(
        "integrations",
        integrationsApi.getIntegrations,
        {
            onError: (error) => {
                console.error(error);
                toast.error("Could not load integrations!");
            },
        }
    );

    const handleDisableIntegration = async (integrationId) => {
        try {
            await integrationsApi.deleteIntegration(integrationId);
            // Refetch integrations data after successful deletion
            refetchIntegrations();
            toast.success("Integration disabled successfully!");
        } catch (error) {
            console.error("Error disabling integration:", error);
            toast.error("Could not disable integration!");
        }
    };

    return (
        <Container className="mt-4">
            <h4 className="text-center mb-4">Profile Settings</h4>
            <Card className="mb-4">
                <Card.Body>
                    <Card.Title>Personal Information</Card.Title>
                    <Card.Text><strong>Email: </strong>{profileData?.email}</Card.Text>
                    <Card.Text><strong>Username: </strong>{profileData?.username}</Card.Text>
                    <Card.Text><strong>Account Created: </strong>{timestampToString(profileData?.created)}</Card.Text>
                </Card.Body>
            </Card>

            <Card className="mb-4">
                <Card.Body>
                    <Card.Title>Workout History Sharing</Card.Title>
                    <Button className="mb-2" onClick={handleGenerateToken}>
                        Generate & Copy Link <i className="fa fa-qrcode" aria-hidden="true" />
                    </Button>
                    <Card.Text><strong>Users who can view your workout history:</strong></Card.Text>
                    {viewersLoading ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : (
                        <ListGroup>
                            {viewers?.map((viewer) => (
                                <ListGroup.Item key={viewer.username} className="d-flex justify-content-between align-items-center">
                                    {viewer.username}
                                    <Button variant="danger" size="sm" onClick={() => handleRevokeAccess(viewer.username)}>
                                        Revoke Access
                                    </Button>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    )}
                </Card.Body>
            </Card>

            <Card className="mb-4">
                <Card.Body>
                    <Card.Title>Preferences</Card.Title>
                    <Card.Text><strong>Weight Unit:</strong></Card.Text>
                    <ToggleButtonGroup
                        name="metric-system"
                        type="radio"
                        value={weightUnit}
                        size="sm"
                        className="mb-2"
                        onChange={handleWeightUnitChange}
                    >
                        <ToggleButton
                            id="btn-kg"
                            variant={weightUnit === "kg" ? "primary" : "secondary"}
                            value="kg"
                        >
                            Metric (kg)
                        </ToggleButton>
                        <ToggleButton
                            id="btn-lbs"
                            variant={weightUnit === "lbs" ? "primary" : "secondary"}
                            value="lbs"
                        >
                            Imperial (lbs)
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Card.Text><strong>Appearance:</strong></Card.Text>
                    <Button 
                        size="sm"
                        className="mb-2"
                        variant="secondary" 
                        onClick={toggleDarkMode}
                    >
                        Toggle Dark/Light Mode
                    </Button>
                </Card.Body>
            </Card>

            <Card className="mb-4">
                <Card.Body>
                    <Card.Title>Integrations</Card.Title>
                    {isLoadingIntegrations ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : integrations?.length === 0 ? (
                        <Card.Text>No integrations found!</Card.Text>
                    ) : (
                        integrations.map((integration) => (
                            <div className="d-flex justify-content-between align-items-center mb-2" key={integration.id}>
                                <strong>{integration.name}:</strong>
                                <Button variant="danger" size="sm" onClick={() => handleDisableIntegration(integration.client_id)}>
                                    Disable
                                </Button>
                            </div>
                        ))
                    )}
                </Card.Body>
            </Card>

            <div className="text-center">
                <Button variant="danger" size="lg" onClick={() => logoutMutation.mutate()}>Log out</Button>
            </div>

            <Modal show={showQRModal} onHide={() => setShowQRModal(false)} centered>
                <Modal.Body className="d-flex justify-content-center">
                    <QRCode value={qrValue} size={360} />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <div className="mt-2">
                        <p className="text-center">
                            Copy the link below to share your workout history with others.
                            You can also use the QR code above to share it with others.
                        </p>
                        <div
                            className="text-center text-primary"
                            style={{ textDecoration: 'underline' }}
                            onClick={handleLinkClick}
                        >
                            {qrValue}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default Profile;
