import { useDispatch, useSelector } from "react-redux";
import React, {useState} from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { addExerciseDetails, deleteExerciseDetails, updateExerciseDetails } from "../../../redux/slices/workoutSlice";
import DurationPickerModal from "./DurationPickerModal";
import TempoPickerModal from "./TempoPickerModal";

import { useConfirmationModalContext } from "../../Common/ConfirmationModalContext";


const AddWorkoutExerciseDetails = ({ exercise, exerciseIndex, isSuperset }) => {
    const dispatch = useDispatch();
    const weightUnit = useSelector((state) => state.auth.weightUnit);

    // const exerciseTypesWithWeight = ['Barbell', 'Dumbbell', 'Machine', 'Bands'];
    // const exerciseTypesWithDuration = ['Cardio'];

    const [showDetails, setShowDetails] = React.useState([]);

    // Update shouldShowDetail to check the specific set's details visibility
    const shouldShowDetail = (detailType, index) => {
        return exercise['workout_exercise_details'][index][detailType] || showDetails[index]?.[detailType];
    };

    const handleToggleDetailVisibility = (detailType, index, isVisible) => {
        // Update the visibility state for the specific detail of a specific set
        const updatedShowDetails = [...showDetails];
        if (!updatedShowDetails[index]) {
            updatedShowDetails[index] = {};
        }
        updatedShowDetails[index][detailType] = isVisible;
        setShowDetails(updatedShowDetails);
    };

    const handleAddExerciseDetail = () => {
        const lastDetail = exercise["workout_exercise_details"][exercise["workout_exercise_details"].length - 1];
        const newDetail = lastDetail ? { ...lastDetail } : undefined;
        // newDetail.notes = exerciseNotes;  
        dispatch(addExerciseDetails({ exerciseIndex, newDetail }));
    };    

    const handleInputChange = (index, event) => {
        if (event.target.validity.valid) {
            let value;
            if (event.target.type === 'checkbox') {
                value = event.target.checked; // Handle checkbox separately
            } else if (event.target.type === 'number') {
                // Use parseFloat for number inputs to handle decimals properly
                value = parseFloat(event.target.value);
            } else {
                value = event.target.value; // Handle other types as strings by default
            }
    
            dispatch(updateExerciseDetails({
                exerciseIndex, // Ensure this variable is correctly defined or passed into your function
                index,
                name: event.target.name,
                value: value,
            }));
        }
    };    

    const handleDurationChange = (index, duration) => {
        dispatch(updateExerciseDetails({
            exerciseIndex,
            index,
            name: 'duration',
            value: duration,
        }));
    };

    const handleTempoChange = (index, tempo) => {
        dispatch(updateExerciseDetails({
            exerciseIndex,
            index,
            name: 'tempo',
            value: tempo,
        }));
    };

    const handleRestChange = (index, rest) => {
        dispatch(updateExerciseDetails({
            exerciseIndex,
            index,
            name: 'rest',
            value: rest,
        }));
    };

    const toggleCompleted = (index, completed) => {
        dispatch(updateExerciseDetails({
            exerciseIndex,
            index,
            name: 'completed',
            value: !completed,
        }));
    };


    const { requestConfirm } = useConfirmationModalContext();
    const handleDeleteConfirmation = (index) => {
        requestConfirm({
            message: "Are you sure you want to delete this set?",
            onConfirm: () =>  dispatch(deleteExerciseDetails({ exerciseIndex, index }))

        });
    };

    const [detailVisibility, setDetailVisibility] = useState({});

    // Function to toggle the visibility of a detail row
    const toggleRowVisibility = (index) => {
        // Toggle the visibility state for the row at the given index
        setDetailVisibility(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };


    return (
        <Form>
            <ListGroup variant="flush">
                {exercise["workout_exercise_details"].map((input, index) =>
                    <div key={index} className="mt-0 pt-0 ps-0 pe-0 py-1">

                        <Row key={`detail-row-0-${index}`} className={`align-items-center mx-0 pe-0`}>
                            <Col xs={11}>
                                <Row>
                                    <Form.Group as={Col} xs={2} className={"mx-0 px-1"}>
                                        <Form.Label className={"small-label-text no-wrap my-0 pt-0 pb-0"} column>Reps</Form.Label>
                                        <Form.Control
                                            type="number"
                                            min="0"
                                            className={"px-2"}
                                            max="1000"
                                            step="1"
                                            name="reps"
                                            value={input?.reps}
                                            onChange={(e) => handleInputChange(index, e)}
                                        />
                                    </Form.Group>
                                    {shouldShowDetail('rpe', index) && (
                                        <Form.Group as={Col} xs={2} className={"mx-0 px-1"}>
                                            <Form.Label className={"small-label-text no-wrap my-0 pt-0 pb-0"} column>RPE</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="rpe"
                                                className={"px-2"}
                                                value={input?.rpe || ''}
                                                onChange={(e) => handleInputChange(index, e)}
                                            >
                                            {[...Array(21).keys()].map(i => i / 2).map(value => (
                                                <option key={value} value={value}>{value}</option>
                                            ))}
                                            </Form.Control>
                                        </Form.Group>
                                    )}
                                    {shouldShowDetail('rir', index) && (
                                        <Form.Group as={Col} xs={2} className={"mx-0 px-1"}>
                                            <Form.Label className={"small-label-text no-wrap my-0 pt-0 pb-0"} column>RIR</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="rir"
                                                className={"px-2"}
                                                value={input?.rir || ''}
                                                onChange={(e) => handleInputChange(index, e)}
                                            >
                                            {[...Array(5).keys()].map(i => i).map(value => (
                                                <option key={value} value={value}>{value}</option>
                                            ))}
                                            <option key="6+" value="6">6+</option>
                                            </Form.Control>
                                        </Form.Group>
                                    )}
                                    {/* Weight Detail Field */}
                                    {shouldShowDetail('weight', index) && (
                                        <Form.Group as={Col} xs={2} className={"mx-0 px-1"}>
                                            <Form.Label className={"small-label-text no-wrap my-0 pt-0 pb-0"} column>Wt({weightUnit})</Form.Label>
                                            <Form.Control
                                                type="number"
                                                className={"px-1"}
                                                min="0"
                                                max="1500"
                                                step="1"
                                                name="weight"
                                                value={input?.weight || ''}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                        </Form.Group>
                                    )}

                                    {shouldShowDetail('distance', index) && (
                                        <Form.Group as={Col} xs={2} className={"mx-0 px-1"}>
                                            <Form.Label className={"small-label-text no-wrap my-0 pt-0 pb-0"} column>Dist(m)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                min="0"
                                                max="50"
                                                step="1"
                                                name="distance"
                                                className={"px-1"}
                                                value={input?.distance}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                        </Form.Group>
                                    )}

                                    {shouldShowDetail('avg_bar_speed', index) && (
                                        <Form.Group as={Col} xs={2} className={"mx-0 px-1"}>
                                            <Form.Label className={"small-label-text no-wrap my-0 pt-0 pb-0"} column>Sp(m/s)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                min="0"
                                                max="50"
                                                step="0.01"
                                                name="avg_bar_speed"
                                                className={"px-1"}
                                                value={input?.avg_bar_speed}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                        </Form.Group>
                                    )}

                                    {shouldShowDetail('tempo', index) && (
                                        <Form.Group as={Col} xs={3} className={"mx-0 px-1"}>
                                            <Form.Label className={"small-label-text no-wrap my-0 pt-0 pb-0"} column>Tempo</Form.Label>
                                            <TempoPickerModal
                                            onTempoChange={(tempo) => handleTempoChange(index, tempo)}
                                            initialTempo={input?.tempo} 
                                            /> 
                                        </Form.Group>
                                    )}

                                    {shouldShowDetail('duration', index) && (
                                        <Form.Group as={Col} xs={3} className={"mx-0 px-1"}>
                                            <Form.Label className={"small-label-text no-wrap my-0 pt-0 pb-0"} column>Duration</Form.Label>
                                            <DurationPickerModal
                                            onDurationChange={(duration) => handleDurationChange(index, duration)}
                                            initialDuration={input?.duration} 
                                            /> 
                                        </Form.Group>
                                    )}

                                    {shouldShowDetail('rest', index) && (
                                        <Form.Group as={Col} xs={3} className={"mx-0 px-1"}>
                                            <Form.Label className={"small-label-text no-wrap my-0 pt-0 pb-0"} column>Rest</Form.Label>
                                            <DurationPickerModal
                                            onDurationChange={(rest) => handleRestChange(index, rest)}
                                            initialDuration={input?.rest} 
                                            />                                        
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} xs={1} className={"mx-0 px-0"}>
                                    <Form.Label className={"small-label-text no-wrap my-0 pt-0 pb-0"} column>&nbsp;</Form.Label>
                                            <Button
                                                onClick={() => toggleRowVisibility(index)}
                                                size="sm"
                                                className='btn-light border bs-body-bg small-label-text mt-0 px-0 w-100'
                                                style={{ height: "28px"}}
                                            >
                                                <i className="fa fa-plus" aria-hidden="true" />
                                            </Button>                                        
                                    </Form.Group>
                                </Row>
                            </Col>
                            <Col xs={1} className="justify-content-end  align-self-start px-0">
                                <div className="d-flex justify-content-end align-items-center w-100">
                                    <div className={"small-label-text no-wrap my-0 pt-0 pb-0"}
                                        onClick={() => handleDeleteConfirmation(index)}
                                        
                                    >
                                        {!isSuperset && (
                                            <i className="fa fa-trash text-danger" aria-hidden="true" />
                                        )}
                                        &nbsp;
                                    </div>
                                </div>

                                <Row className="px-2">
                                    <Col xs={12} className={"ps-2 mx-0 pe-0"}>
                                    <Button
                                        variant={input?.completed ? "success" : "outline-secondary"}
                                        onClick={() => toggleCompleted(index, input?.completed)}
                                        size="sm"
                                        style={{ height: "28px" }}
                                        className="mt-2 px-0 w-100"
                                    >
                                        {input?.completed ? <><i className="fa fa-check" aria-hidden="true" /></> : <i className="fa fa-check" aria-hidden="true" />}
                                    </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {detailVisibility[index] && (
                            <Row key={`detail-row-1-${index}`} className={`align-items-center mt-2 mx-0`}>                            
                            {/* Toggle Button for Weight */}
                            {!shouldShowDetail('weight', index) && (
                                <Col xs="auto" className="m-0 ps-0 pe-1">
                                    <Button
                                        variant="info"
                                        size="sm"
                                        className="inline-block badge rounded-pill bg-info small-label-text  mx-0"
                                        tyle={{ display: 'inline-block', }}
                                        onClick={() => handleToggleDetailVisibility('weight', index, true)}
                                    >
                                        Weight
                                    </Button>
                                </Col>
                            )}
                            {!shouldShowDetail('avg_bar_speed', index) && (
                                <Col xs="auto" className="m-0 ps-0 pe-1">
                                    <Button
                                        variant="info"
                                        size="sm"
                                        className="inline-block badge rounded-pill bg-info small-label-text mx-0"
                                        tyle={{ display: 'inline-block', }}
                                        onClick={() => handleToggleDetailVisibility('avg_bar_speed', index, true)}
                                    >
                                        Bar Speed
                                    </Button>
                                </Col>
                            )}
                            {!shouldShowDetail('distance', index) && (
                                <Col xs="auto" className="m-0 ps-0 pe-1">
                                    <Button
                                        variant="info"
                                        size="sm"
                                        className="inline-block badge rounded-pill bg-info small-label-text mx-0"
                                        tyle={{ display: 'inline-block', }}
                                        onClick={() => handleToggleDetailVisibility('distance', index, true)}
                                    >
                                        Distance
                                    </Button>
                                </Col>
                            )}


                            {!shouldShowDetail('tempo', index) && (
                                <Col xs="auto" className="m-0 ps-0 pe-1">
                                    <Button
                                        variant="info"
                                        size="sm"
                                        className="inline-block badge rounded-pill bg-info small-label-text mx-0"
                                        tyle={{ display: 'inline-block', }}
                                        onClick={() => handleToggleDetailVisibility('tempo', index, true)}
                                    >
                                        Tempo
                                    </Button>
                                </Col>
                            )}

                            {!shouldShowDetail('duration', index) && (
                                <Col xs="auto" className="m-0 ps-0 pe-1">
                                    <Button
                                        variant="info"
                                        size="sm"
                                        className="inline-block badge rounded-pill bg-info small-label-text mx-0"
                                        tyle={{ display: 'inline-block', }}
                                        onClick={() => handleToggleDetailVisibility('duration', index, true)}
                                    >
                                        Duration
                                    </Button>
                                </Col>
                            )}
                            {!shouldShowDetail('rpe', index) && (
                                <Col xs="auto" className="m-0 ps-0 pe-1">
                                    <Button
                                        variant="info"
                                        size="sm"
                                        className="inline-block badge rounded-pill bg-info small-label-text mx-0"
                                        tyle={{ display: 'inline-block', }}
                                        onClick={() => handleToggleDetailVisibility('rpe', index, true)}
                                    >
                                        RPE
                                    </Button>
                                </Col>
                            )}
                            {!shouldShowDetail('rir', index) && (
                                <Col xs="auto" className="m-0 ps-0 pe-1">
                                    <Button
                                        variant="info"
                                        size="sm"
                                        className="inline-block badge rounded-pill bg-info small-label-text mx-0"
                                        tyle={{ display: 'inline-block', }}
                                        onClick={() => handleToggleDetailVisibility('rir', index, true)}
                                    >
                                        RIR
                                    </Button>
                                </Col>
                            )}
                            {!shouldShowDetail('rest', index) && (
                                <Col xs="auto" className="m-0 ps-0 pe-1">
                                    <Button
                                        variant="primary"
                                        size="sm"
                                        className="inline-block badge rounded-pill bg-primary small-label-text mx-0"
                                        tyle={{ display: 'inline-block', }}
                                        onClick={() => handleToggleDetailVisibility('rest', index, true)}
                                    >
                                        Rest
                                    </Button>
                                </Col>
                            )}
                        </Row>)}
                    </div>
                )}
            </ListGroup>

            {!isSuperset && (

                <Col xs={6} className="mt-0 mx-0 px-1 align-self-start">

                    <Button
                        variant="secondary"
                        size="sm"
                        className="mt-2 py-0"
                        onClick={handleAddExerciseDetail}
                    >
                        + Add Set
                    </Button>

                </Col>
                
                
                )}
        </Form>

    );
};

export default AddWorkoutExerciseDetails;