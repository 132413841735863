import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';

import * as api from "../api/workoutApi";
import * as folderApi from "../api/folderApi";

// import { compress, decompress } from 'brotli-unicode';
// import { encode as base64Encode, decode as base64Decode } from 'js-base64';

const typeMapping = {
    'Barbell': 'B',
    'Dumbbell': 'D',
    'Machine': 'M',
    'Bodyweight': 'BW',
    'Bands': 'BN',
    'Cardio': 'C',
    'Plyometric': 'P',
    'Mobility': 'MB',
    'Stretching': 'S',
    'Yoga': 'Y',
    'Other': 'O',

    'exercise': 'e',
    'name': 'n',
    'type': 't',
    'workout_exercise_details': 'x',
    'superset_number': 'ss',
    'sets': 's',
    'reps': 'r',
    'avg_bar_speed': 'b',
    'weight': 'w',
    'tempo': 'te',
    'duration': 'd',
    'rest': 're',
    'completed': 'c',
};

function replaceTypes(data, mapping) {
    const stringifiedData = JSON.stringify(data);

    // Replace values based on mapping
    const replaced = Object.entries(mapping).reduce((acc, [key, value]) => {
        // Use a regex to target specific JSON key patterns to avoid replacing unintended text
        const regex = new RegExp(`"${key}"`, 'g');
        return acc.replace(regex, `"${value}"`);
    }, stringifiedData);

    return replaced;
}

function revertTypes(data, mapping) {
    const revertedMapping = Object.fromEntries(Object.entries(mapping).map(([k, v]) => [v, k]));

    // Revert the compressed keys back to their original forms
    const reverted = Object.entries(revertedMapping).reduce((acc, [key, value]) => {
        const regex = new RegExp(`"${key}"`, 'g');
        return acc.replace(regex, `"${value}"`);
    }, data);

    return reverted;
}


export const encodeWorkoutUUIDToURL = async (workout) => {
    // try {
    //     if (typeof workout === 'object') {
    //         // Compress workout object if it's an object
    //         const reducedData = replaceTypes(workout, typeMapping);
    //         const compressed = compressToEncodedURIComponent(reducedData);
    //         return `${window.location.origin}/workout/#${compressed}`;
    //     } else if (typeof workout === 'string') {
    //         // If it's just an ID, encode the ID directly
            const sanitizedWorkoutName = workout.name.replace(/\s+/g, '_');
            return `${window.location.origin}/workout/#${workout.share_uuid}$${sanitizedWorkoutName}`;
    //     }
    // } catch (error) {
    //     console.error('Compression error:', error);
    //     return null;
    // }
};

export const encodeFolderShareTokenToURL = (token) => {
            return `${window.location.origin}/workout/#${token}$AccessFolder`;
};

export const encodeUserWorkoutHistoryShareTokenToURL = (token) => {
    return `${window.location.origin}/workout/#${token}$AccessUserWorkoutHistory`;
};

export const encodeWorkoutStateToURL = async (workout) => {
    try {
        if (typeof workout === 'object') {
            // Compress workout object if it's an object
            const reducedData = replaceTypes(workout, typeMapping);
            const compressed = compressToEncodedURIComponent(reducedData);
            return `${window.location.origin}/workout/#${compressed}`;
        } else if (typeof workout === 'string') {
            // If it's just an ID, encode the ID directly
            return `${window.location.origin}/workout/#${workout}`;
        }
    } catch (error) {
        console.error('Compression error:', error);
        return null;
    }
};

export const checkAccessFolderFromURL = async () => {
    try {
        const hash = window.location.hash.substring(1);
        const parts = hash.split('$');
        const token = parts[0];
        const additionalData = parts[1];

        if (additionalData === 'AccessFolder') {
            const data = await folderApi.accessFolderWithToken(token);
            return `Access added for folder: ${data.workoutfolder_name}`;
        }
        return false;
    } catch (error) {
        console.error('Error accessing folder from URL:', error);
        return `Error accessing folder, perhaps the link is expired or already used.`;
    }
};


export const checkAccessUserWorkoutHistoryFromURL = async () => {
    try {
        const hash = window.location.hash.substring(1);
        const parts = hash.split('$');
        const token = parts[0];
        const additionalData = parts[1];

        if (additionalData === 'AccessUserWorkoutHistory') {
            await api.accessUserWorkoutHisoryWithToken(token);
            return `Access added for new user!`;
        }
        return false;
    } catch (error) {
        console.error('Error accessing folder from URL:', error);
        return `Error accessing folder, perhaps the link is expired or already used.`;
    }
};



export const decodeWorkoutStateFromURL = async () => {
    console.log(window.location.hash)
    const hash = window.location.hash.substring(1);
    const parts = hash.split('$');
    const workoutIdOrState = parts[0];
    const additionalData = parts[1]; // This will be undefined if no additional data is present

    console.log(workoutIdOrState);
    console.log(additionalData); // Log the additional data, if present

    try {
        // UUID v4 regex pattern
        const uuidV4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        if (uuidV4Regex.test(workoutIdOrState)) {
            // If the workoutIdOrState matches a UUID pattern, treat it as a workout UUID
            const workoutData = await api.getWorkoutByUUID(workoutIdOrState);
            return workoutData;
        } else {
            // Otherwise, assume it's a compressed workout state
            const decompressed = decompressFromEncodedURIComponent(workoutIdOrState);
            const originalData = revertTypes(decompressed, typeMapping);
            return JSON.parse(originalData);
        }
    } catch (error) {
        // console.error('Decoding error:', error);
        return null;
    }
};