import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import * as apiFolder from '../api/folderApi';

// export const useAddWorkoutToFolderMutation = () => {
//     const queryClient = useQueryClient();
//     return useMutation(apiFolder.addWorkoutToFolder, {
//         onSuccess: () => {
//             queryClient.invalidateQueries('folders');
//             toast.info('Workout added to folder successfully!');
//         },
//         onError: (error) => {
//             toast.error(`Error adding workout to folder: ${error.message}`);
//         }
//     });
// };

export const useAddWorkoutToFolderMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(({ folderId, workoutIds }) => apiFolder.addWorkoutToFolder(folderId, workoutIds), {
        onSuccess: () => {
            queryClient.invalidateQueries('folders');
            queryClient.invalidateQueries('workout');
            toast.info('Workout added to folder successfully!');
        },
        onError: (error) => {
            toast.error(`Error adding workout to folder: ${error.message}`);
        }
    });
};

export const useRemoveWorkoutFromFolderMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(({ folderId, workoutId }) => apiFolder.removeWorkoutFromFolder(folderId, workoutId), {
        onSuccess: () => {
            queryClient.invalidateQueries('folders');
            queryClient.invalidateQueries('workout');

            toast.info('Workout removed from folder successfully!');
        },
        onError: (error) => {
            toast.error(`Error removing workout from folder: ${error.message}`);
        }
    });
};

export const useDeleteFolderMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((folderId) => apiFolder.deleteFolder(folderId), {
        onSuccess: () => {
            queryClient.invalidateQueries('folders');
            queryClient.invalidateQueries('workout');
            toast.info('Folder deleted successfully!');
        },
        onError: (error) => {
            toast.error(`Error deleting folder: ${error.message}`);
        }
    });
};

export const useRenameFolderMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(({ folderId, newName }) => apiFolder.renameFolder(folderId, newName), {
        onSuccess: () => {
            queryClient.invalidateQueries('folders');
            toast.info('Folder renamed successfully!');
        },
        onError: (error) => {
            toast.error(`Error renaming folder: ${error.message}`);
        }
    });
};