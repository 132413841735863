// import React, { useState } from 'react';
// import {toast} from "react-toastify";
// import { ListGroup, Row, Col, Button, Badge } from 'react-bootstrap';
// import { useDispatch } from 'react-redux';
// import { initializeWorkout } from '../../../redux/slices/workoutSlice';
// import WorkoutExercise from '../../History/WorkoutExercise';
// import { timestampToString } from '../../../utils/helpers';
// import * as api from "../../../api/workoutApi";


// const WorkoutItem = ({ workout, handleDeleteWorkout, openQRModal, handleGenerateAndCopyLink }) => {
//     const dispatch = useDispatch();
//     const [isExpanded, setIsExpanded] = useState(false);

//     const handleStartWorkout = () => {
//         dispatch(initializeWorkout(workout));
//     };

//     const toggleExpand = () => {
//         setIsExpanded(!isExpanded);
//     };

//     const enableSharing = async () => {
//         if (!workout.shareable) {
//             try {
//                 const updatedWorkout = await api.updateWorkout(workout.id, { shareable: true });
//                 handleGenerateAndCopyLink(updatedWorkout);
//                 toast.success("Workout is now shareable!");
//             } catch (error) {
//                 console.error('Error enabling sharing:', error);
//                 toast.error("Failed to enable sharing!");
//             }
//         } else {
//             handleGenerateAndCopyLink(workout);
//         }
//     };

//     // Extract and join unique exercise names
//     const uniqueExercises = [...new Set(workout.workout_exercises.map(ex => ex.name))].join(', ');

//     return (
//         <ListGroup.Item className="workout mb-2 p-3">
//             <Row>
//                 <Col>
//                     <h5>{workout.name}
//                     <span className='text-secondary ms-3' onClick={() => handleDeleteWorkout(workout.id)}>
//                         <i className="fa fa-trash" aria-hidden="true" />
//                     </span>
//                     </h5>
//                 </Col>
//                 <Col className="col-auto">
//                     <Button className="me-1" variant="success" onClick={handleStartWorkout}>
//                         Start Workout
//                     </Button>
//                 </Col>
//             </Row>
//             <div className="text-secondary">
//                 {workout.status} | {timestampToString(workout.created)}
//             </div>
//             {!isExpanded && 
//                 <div className="mt-2">
//                     <span className="text-muted">{uniqueExercises}</span>
//                 </div>
//             }
//             {isExpanded && <WorkoutExercise exercises={workout.workout_exercises} />}
//             <Row className="mt-1">
//                 <Col>
//                     <Button variant="outline-secondary" onClick={toggleExpand} className='border-0'>
//                         {isExpanded ? <>show less </> : <>show more <i className="fa fa-angle-down" aria-hidden="true"/></>}
//                     </Button>
//                     <Badge pill bg="secondary" onClick={enableSharing}>
//                         {workout.shareable ? "Shareable" : "Make Shareable"}
//                     </Badge>
//                     <Button variant="primary" className="me-1 float-end" onClick={() => handleGenerateAndCopyLink(workout)}>Share</Button>
//                     <Button variant="primary" className="me-1 float-end" onClick={() => openQRModal(workout)}><i className="fa fa-qrcode" aria-hidden="true"/></Button>
                
//                 </Col>
//             </Row>
//         </ListGroup.Item>
//     );
// };

// export default WorkoutItem;

import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from "react-toastify";
import { ListGroup, Row, Col, Button, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { initializeWorkout } from '../../../redux/slices/workoutSlice';
import WorkoutExercise from '../../History/WorkoutExercise';
import { timestampToString } from '../../../utils/helpers';
import * as api from "../../../api/workoutApi";

const WorkoutItem = ({ workout, handleDeleteWorkout, openQRModal, handleGenerateAndCopyLink }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [isExpanded, setIsExpanded] = useState(false);

    const handleStartWorkout = () => {
        dispatch(initializeWorkout(workout));
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const shareClick = (workout) => {
        handleGenerateAndCopyLink(workout);
        openQRModal(workout);
    };

    const handleShareClick = async () => {
        if (!workout.shareable) {
            try {
                const updatedWorkout = await api.updateWorkout({ id: workout.id, data: { shareable: !workout.shareable }});
                await queryClient.invalidateQueries('workout');
                await queryClient.invalidateQueries('folders');
                toast.success(`Workout is ${workout.shareable ? "not yet shareable, please refresh page" : "now shareable"}!`);
                if (!workout.shareable) {
                    handleGenerateAndCopyLink(updatedWorkout); // Assuming this needs the latest data
                }
                
            } catch (error) {
                console.error('Error making workout shareable:', error);
                toast.error("Failed to make workout shareable!");
            }
        } else {
            try {
                const updatedWorkout = await api.updateWorkout({ id: workout.id, data: { shareable: !workout.shareable }});
                queryClient.invalidateQueries('workout');
                queryClient.invalidateQueries('folders');
                toast.success("Workout is no longer sharable!");
                // openQRModal(updatedWorkout);
            } catch (error) {
                console.error('Error making workout shareable:', error);
                toast.error("Failed to make workout shareable!");
            }
        }
    };

    // Extract and join unique exercise names
    const uniqueExercises = [...new Set(workout.workout_exercises.map(ex => ex.name))].join(', ');

    return (
        <ListGroup.Item className="workout mb-2 p-3">
            <Row>
                <Col>
                    <h5>{workout.name}
                    </h5>
                </Col>
                <Col className="col-auto">
                    <Dropdown align="end">
                        <Button variant="secondary" className="px-2 py-0" id="dropdown-custom-components">
                            <Dropdown.Toggle as="span" bsPrefix="custom-toggle">
                                <i className="fas fa-ellipsis"></i> {/* Font Awesome three dots icon */}
                            </Dropdown.Toggle>
                        </Button>
                        <Dropdown.Menu className='bg-body'>
                            <Dropdown.Item onClick={() => handleShareClick()}>{workout.shareable ? "Unshare Workout" : "Share Workout"}</Dropdown.Item>
                            {workout.shareable && (
                                <>
                                    <Dropdown.Item onClick={() => handleGenerateAndCopyLink(workout)}>Copy Share Link</Dropdown.Item>
                                    <Dropdown.Item onClick={() => openQRModal(workout)}>Show QR Code</Dropdown.Item>
                                </>
                            )}
                            <Dropdown.Item onClick={() => handleDeleteWorkout(workout.id)}>Delete Workout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <div className="text-secondary">
                {workout.status} | {timestampToString(workout.created)}
            </div>
            {!isExpanded && 
                <div className="mt-2">
                    <span className="text-muted">{uniqueExercises}</span>
                </div>
            }
            {isExpanded && <WorkoutExercise exercises={workout.workout_exercises} />}
            <Row className="mt-1">
                 <Col>
                     <Button variant="outline-secondary" onClick={toggleExpand} className='border-0'>
                         {isExpanded ? <>show less </> : <>show more <i className="fa fa-angle-down" aria-hidden="true"/></>}
                     </Button>
                     <Button variant="primary" className="me-1 float-end"  onClick={handleStartWorkout}>
                        Open Workout
                     </Button>
                     {/* <Button variant="primary" className="me-1 float-end" onClick={() => handleGenerateAndCopyLink(workout)}>Share</Button> */}
                     {workout.shareable && (
                     <Button variant="primary" className="me-1 float-end" onClick={() => shareClick(workout)}><i className="fa fa-qrcode" aria-hidden="true" /></Button>
                     )}
                 </Col>
             </Row>
        </ListGroup.Item>
    );
};

export default WorkoutItem;
