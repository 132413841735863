import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { updateProfile, updateWeightUnit, endProfileLoading } from '../redux/slices/authSlice';
import * as profileApi from '../api/profileApi';

const UserProfileFetcher = () => {
    const dispatch = useDispatch();
    const authenticated = useSelector(state => state.auth.authenticated);

    useQuery('profile', profileApi.getProfile, {
        enabled: authenticated,
        onSuccess: (data) => {
            if (data && data["weight_system"]) {
                dispatch(updateProfile(data));
                dispatch(updateWeightUnit(data["weight_system"]));
            } else {
                console.error("Profile data is missing 'weight_system' property:", data);
            }
            dispatch(endProfileLoading());  // End loading only on success
        },
        onError: (error) => {
            console.log("Failed to fetch profile data:", error);
            // Optionally, handle the error state differently
        },
    });

    return null; // This component does not render anything
};

export default UserProfileFetcher;

