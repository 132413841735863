import axiosInstance from "./instances/axiosInstance";
import {apiRoutes} from "../utils/routes";

export const getWorkoutHistory= (status = '', username = '') => {
    let url = apiRoutes.workout;
    if (status) {
        url += `?status=${status}`;
    }
    if (status && username) {
        url += `&username=${username}`;
    }
    // console.log(`Fetching workout history with URL: ${url}`); // Log the URL being requested
    return axiosInstance.get(url)
        .then(response => {
            // console.log('Workout history data received:', response.data); // Log the data received
            return response.data;
        })
        .catch(error => {
            console.error('Error fetching workout history:', error); // Log any errors
            throw error; // Rethrow the error to ensure it can be caught or handled by the caller
        });
};

export const getWorkoutTemplates= () => {
    let url = apiRoutes.workout;
    url += `?status=Template`;
    // console.log(`Fetching workout history with URL: ${url}`); // Log the URL being requested
    return axiosInstance.get(url)
        .then(response => {
            // console.log('Workout history data received:', response.data); // Log the data received
            return response.data;
        })
        .catch(error => {
            console.error('Error fetching workout templates:', error); // Log any errors
            throw error; // Rethrow the error to ensure it can be caught or handled by the caller
        });
};

export const updateWorkout = ({ id, data }) => {
    console.log('Sending update request for workout_id:', id, 'with data:', data);  // Log the data being sent
    return axiosInstance.patch(`${apiRoutes.workout}${id}/`, data)
        .then(response => {
            // console.log('Workout updated successfully:', response.data); // Log successful update
            return response.data;
        })
        .catch(error => {
            console.error('Error updating workout:', error); // Log any errors
            console.error('Failed request data:', data); // Log the problematic data
            throw error; // Rethrow the error to ensure it can be caught or handled by the caller
        });
};

export const putWorkout = ({ id, data }) => {
    console.log('Sending update request for workout_id:', id, 'with data:', data);  // Log the data being sent
    return axiosInstance.put(`${apiRoutes.workout}${id}/`, data)
        .then(response => {
            // console.log('Workout updated successfully:', response.data); // Log successful update
            return response.data;
        })
        .catch(error => {
            console.error('Error updating workout:', error); // Log any errors
            console.error('Failed request data:', data); // Log the problematic data
            throw error; // Rethrow the error to ensure it can be caught or handled by the caller
        });
};


export const getWorkoutByUUID = (uuid) => axiosInstance.get(`${apiRoutes.workout}${uuid}/`).then(response => response.data);

export const getWorkout = (id) => axiosInstance.get(`${apiRoutes.workout}${id}/`).then(response => response.data);

export const createWorkout = (data) => { 
    console.log('Creating workout with data:', data);  
    axiosInstance.post(apiRoutes.workout, data).then(response => response.data)
};

export const deleteWorkout = (id) => axiosInstance.delete(`${apiRoutes.workout}${id}/`).then(response => response.data);

export const generateTokenForuserWorkoutHistory = (validityDurationMinutes = 5) => {
    const url = `${apiRoutes.workout}generate-token/`;
    return axiosInstance.post(url, { validity_duration_minutes: validityDurationMinutes })
        .then(response => {
            console.log('Token generated for user share:', response.data.token);
            return response.data;
        })
        .catch(error => {
            console.error('Error generating token for user share:', error);
            throw error;
        });
};

// Function to access a folder using a token
export const accessUserWorkoutHisoryWithToken = (token) => {
    const url = `${apiRoutes.workout}access-user-workouts/${token}/`;
    return axiosInstance.get(url)
        .then(response => {
            console.log('Accessed user workout history with token:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error accessing user workout history with token:', error);
            throw error;
        });
};

