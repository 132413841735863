import React, { useState, useEffect, useRef } from "react";

const Timer = ({ startTime }) => {
    const [workoutTimer, setWorkoutTimer] = useState(0);
    const workoutTimerRef = useRef(0);

    useEffect(() => {
        workoutTimerRef.current = Math.floor((Date.now() - startTime) / 1000);

        const intervalId = setInterval(() => {
            workoutTimerRef.current += 1;
            setWorkoutTimer(workoutTimerRef.current);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [startTime]);

    return (
        <div>
            <strong>Workout Time:</strong> {Math.floor(workoutTimer / 3600).toString().padStart(2, '0')}:{Math.floor((workoutTimer % 3600) / 60).toString().padStart(2, '0')}:{(workoutTimer % 60).toString().padStart(2, '0')}
        </div>
    );
};

export default Timer;
