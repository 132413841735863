import axiosInstance from "./instances/axiosInstance";
import {apiRoutes} from "../utils/routes";

export const getExercises = () => axiosInstance.get(apiRoutes.exercise).then(response => response.data);
export const getExercise = (id) => {
    // console.log(`Making API call to get exercise with ID: ${id}`); // Log when the API call is initiated
    return axiosInstance.get(`${apiRoutes.exercise}${id}/`)
      .then(response => {
        // console.log(`Successfully retrieved exercise with ID: ${id}`, response.data); // Log successful response data
        return response.data;
      })
      .catch(error => {
        console.error(`Failed to retrieve exercise with ID: ${id}`, error); // Log any errors
        throw error; // Rethrow the error so the calling function can handle it
      });
  };
export const deleteExercise = (id) => axiosInstance.delete(`${apiRoutes.exercise}${id}/`).then(response => response.data);
export const getBodyParts = () => {
    // console.log('Making API call to get body parts'); // Log when the API call is initiated
    return axiosInstance.get(apiRoutes.bodyParts)
      .then(response => {
        // console.log('Successfully retrieved body parts', response.data); // Log successful response data
        return response.data;
      })
      .catch(error => {
        console.error('Failed to retrieve body parts', error); // Log any errors
        throw error; // Rethrow the error
    });
};
export const getType = () => axiosInstance.get(apiRoutes.type).then(response => response.data);
export const createExercise = (data, onUploadProgress) => 
    axiosInstance.post(apiRoutes.exercise, data, {
        onUploadProgress: progressEvent => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onUploadProgress(percentCompleted);
        }
    }).then(response => response.data);
export const updateExercise = (id, data, onUploadProgress) =>
    axiosInstance.put(`${apiRoutes.exercise}${id}/`, data, {
        onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onUploadProgress(percentCompleted);
        }
    }).then(response => response.data);


