import {useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "./Select";
import SelectBodyPart from "./SelectBodyPart";
import * as api from "../../../api/exerciseApi";
import { ProgressBar } from 'react-bootstrap';



const NewExerciseModal = (props) => {
    const [exerciseName, setExerciseName] = useState("");
    const [selectedBodyPart, setSelectedBodyPart] = useState({ id: 0, name: 'Full Body' });
    const [selectedType, setSelectedType] = useState("");
    const [exerciseDescription, setExerciseDescription] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [selectedSharing, setSelectedSharing] = useState("Private"); // Default sharing setting

    
    const queryClient = useQueryClient();
    const bodyParts = queryClient.getQueryData("bodyParts") || [];
    const type = queryClient.getQueryData("type");

    const createExerciseMutation = useMutation(({data}) => 
        api.createExercise(data, progress => setUploadProgress(progress)), {
            onError: (error) => {
                console.error(error);
                toast.error("Could not save exercise.");
            },
            onSuccess: (data) => {
                queryClient.invalidateQueries("exercise");
                toast.success(`Exercise: ${data.name} is saved!`);
                clear();
            },
            onSettled: () => {
                setUploadProgress(0); 
            }
        }
    );


    const clear = () => {
        setExerciseName("");
        setSelectedBodyPart({ id: 0, name: 'Full Body' }); // Reset to default or maintain last valid selection
        setSelectedType("");
        setExerciseDescription("");
        setImages([]); // Reset images
        setVideos([]); // Reset videos
        setSelectedSharing("Private"); // Reset sharing setting to default
        props.onHide();
    };
    

    const save = (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append("name", exerciseName.trim());
        if (selectedBodyPart && selectedBodyPart.id) {
            formData.append("body_parts", selectedBodyPart.id.toString());
        } else if (bodyParts.length > 0) {
            // Fallback to the first body part's id if none is selected (you might want to adjust this logic)
            formData.append("body_parts", bodyParts[0].id.toString());
        }        formData.append("type", selectedType ? selectedType : type?.[0]);
        formData.append("description", exerciseDescription.trim());
        formData.append("sharing", selectedSharing); // Append sharing setting

        images.forEach((image) => {
            if (image.size <= parseInt(process.env.REACT_APP_DATA_UPLOAD_MAX_MEMORY_SIZE, 10)) {
                formData.append("images", image);
            } else {
                toast.error("One or more images exceed the 50MB size limit.");
                return;
            }
        });
        
        videos.forEach((video) => {
            if (video.size <= parseInt(process.env.REACT_APP_DATA_UPLOAD_MAX_MEMORY_SIZE, 10)) {
                formData.append("videos", video);
            } else {
                toast.error("One or more videos exceed the 50MB size limit.");
                return;
            }
        });
        console.log(formData);
        createExerciseMutation.mutate({ data: formData });

    };


    return (
        <Modal {...props} size="lg" aria-labelledby="newExerciseModal" onHide={clear}>
            <Form onSubmit={save}>
                <Modal.Header closeButton>
                    <Modal.Title id="newExerciseModal">
                        Add a new exercise
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="inputExerciseName" className="mb-2">
                        <Form.Label>Exercise name</Form.Label>
                        <Form.Control
                            type="text"
                            name="exerciseName"
                            placeholder="Enter exercise name"
                            value={exerciseName}
                            required
                            onChange={(e) => setExerciseName(e.target.value)}
                        />
                    </Form.Group>

                    <SelectBodyPart
                    label="Body Part"
                    options={bodyParts.map(bp => ({ id: bp.id.toString(), name: bp.name }))}
                    selectValue={selectedBodyPart}
                    onSelect={(e) => {
                        const selectedOption = bodyParts.find(bp => bp.id.toString() === e.target.value);
                        setSelectedBodyPart(selectedOption || { id: 0, name: 'Full Body' });
                    }}                    
                    />

                    <Select
                        label="Type"
                        options={type}
                        selectValue={selectedType}
                        onSelect={(e) => setSelectedType(e.target.value)}
                    />

                    <Form.Group controlId="inputExerciseDescription">
                        <Form.Label>Exercise description</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="exerciseDescription"
                            placeholder="Enter exercise description"
                            value={exerciseDescription}
                            onChange={(e) => setExerciseDescription(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="inputExerciseImages" className="mb-2">
                        <Form.Label>Exercise Images</Form.Label>
                        <Form.Control
                            type="file"
                            multiple
                            onChange={(e) => setImages([...e.target.files])}
                        />
                    </Form.Group>

                    <Form.Group controlId="inputExerciseVideos" className="mb-2">
                        <Form.Label>Exercise Videos</Form.Label>
                        <Form.Control
                            type="file"
                            multiple
                            onChange={(e) => setVideos([...e.target.files])}
                        />
                    </Form.Group>

                    <Form.Group controlId="inputExerciseSharing" className="mb-3">
                        <Form.Label>Who can see this exercise?</Form.Label>
                        <Form.Select
                            aria-label="Sharing settings"
                            value={selectedSharing}
                            onChange={(e) => setSelectedSharing(e.target.value)}
                        >
                            <option value="Private">Only Me</option>
                            <option value="Shareable">Shareable</option>
                            <option value="Public">Public</option>
                        </Form.Select>
                        <Form.Text className="text-muted">
                            Choose who can see this exercise.
                        </Form.Text>
                    </Form.Group>

                {uploadProgress > 0 && <ProgressBar now={uploadProgress} label={`Uploading, please wait...`} />}
                </Modal.Body>
                {uploadProgress === 0 && 
                <Modal.Footer>
                    <Button variant="secondary" onClick={clear}>Cancel</Button>
                    <Button variant="primary" type="submit">Save</Button>
                </Modal.Footer>}
            </Form>
        </Modal>
    );
};

export default NewExerciseModal;
